import { TestContext } from 'yup';
import { AnyObject } from 'yup/lib/types';
import { DIAGNOSIS_CODES_MAP, HCFA_FIELDS } from './fields';

import moment from 'moment';
import { isValidLuhn } from 'helpers/checkLuhnLogic';
import { HCFA23NumberType, HCFA33PhysicianEnum } from './constants';
import { dxLookup, procedureLookup, zipLookup } from 'api/lookups';
import { getTokenFromSessionStorage } from 'helpers/getTokenFromSessionStorage';
import { alphaNum, aphaNumWithHyphensRegex, cliaNumber, numericLengthFiveOrNine } from './validations';

import { getFormProvidersDataFromGlobalObject } from './putFormProvidersDataInGlobalObject';
import { checkIsPostBox } from 'helpers/checkIsPostBox';

type ValueType = string | null | undefined;
type ContextType = TestContext<AnyObject>;
export type HcfaTableChange = [...undefined[], Record<string, string>];
export type HcfaFormValueChange = Record<string, string | HcfaTableChange>;

// const POBoxAddressPartials = [
//   'po box',
//   'p.o. box',
//   'p.o.box',
//   'p o box',
//   'p.o box',
//   'pobox',
//   'postbox',
//   'post box',
//   'lock box',
//   'lockbox'
// ];
// const POBOXLobStates = ['ar', 'oh', 'mi'];

const ValidationsDependenciesMap: Record<string, string[]> = {
  [HCFA_FIELDS.patientFirstName]: [HCFA_FIELDS.patRelInfo6],
  [HCFA_FIELDS.patientLastName]: [HCFA_FIELDS.patRelInfo6],
  [HCFA_FIELDS.insuredFirstName]: [HCFA_FIELDS.patRelInfo6],
  [HCFA_FIELDS.insuredLastName]: [HCFA_FIELDS.patRelInfo6],
  [HCFA_FIELDS.patRelInfo6]: [
    HCFA_FIELDS.patientFirstName,
    HCFA_FIELDS.insuredDateOfBirth,
    '3_pat_dob',
    HCFA_FIELDS.patientLastName,
    HCFA_FIELDS.insuredFirstName,
    HCFA_FIELDS.insuredLastName,
    HCFA_FIELDS.insuredState
  ],

  [HCFA_FIELDS.autoAccident]: [
    HCFA_FIELDS.accidentState,
    HCFA_FIELDS.currentIllness,
    HCFA_FIELDS.otherQuality,
    HCFA_FIELDS.otherDate
  ],
  [HCFA_FIELDS.otherAccident]: [HCFA_FIELDS.currentIllness, HCFA_FIELDS.otherQuality, HCFA_FIELDS.otherDate],
  [HCFA_FIELDS.employmentStatus]: [HCFA_FIELDS.otherQuality, HCFA_FIELDS.otherDate],
  [HCFA_FIELDS.quality]: [HCFA_FIELDS.currentIllness],
  [HCFA_FIELDS.currentIllness]: [HCFA_FIELDS.quality],
  [HCFA_FIELDS.otherQuality]: [HCFA_FIELDS.otherDate],
  [HCFA_FIELDS.otherDate]: [HCFA_FIELDS.otherQuality],
  [HCFA_FIELDS.outsideLab]: [HCFA_FIELDS.labCharges],
  [HCFA_FIELDS.resubmissionCode]: [HCFA_FIELDS.originalRefNumber],
  [HCFA_FIELDS.HCFA33psysiciancheckbox]: [
    HCFA_FIELDS.billingName,
    HCFA_FIELDS.providersPhysicianLastname,
    HCFA_FIELDS.providersPhysicianFirstname
  ],
  [HCFA_FIELDS.totalCharges]: [HCFA_FIELDS.amountPaid],
  [HCFA_FIELDS.insuredAddress]: [HCFA_FIELDS.insuredCity, HCFA_FIELDS.insuredZip, HCFA_FIELDS.insuredState],
  [HCFA_FIELDS.referringNpi]: [HCFA_FIELDS.referringProviderFirstName, HCFA_FIELDS.referringProviderLastName],
  [HCFA_FIELDS.referringProviderId]: [HCFA_FIELDS.referringProviderFirstName, HCFA_FIELDS.referringProviderLastName],
  [HCFA_FIELDS.referringProviderFirstName]: [HCFA_FIELDS.referringNpi],
  [HCFA_FIELDS.referringProviderLastName]: [HCFA_FIELDS.referringNpi],
  [HCFA_FIELDS.HCFA33Address2]: [HCFA_FIELDS.billingAddress],
  [HCFA_FIELDS.billingNpi]: [
    HCFA_FIELDS.billingAddress,
    HCFA_FIELDS.billingName,
    HCFA_FIELDS.providersPhysicianFirstname,
    HCFA_FIELDS.providersPhysicianLastname,
    HCFA_FIELDS.billingId,
    HCFA_FIELDS.billingIdQuality
  ],
  [HCFA_FIELDS.HCFA33psysiciancheckbox]: [
    HCFA_FIELDS.billingName,
    HCFA_FIELDS.providersPhysicianFirstname,
    HCFA_FIELDS.providersPhysicianLastname,
    HCFA_FIELDS.billingIdQuality
  ],
  [HCFA_FIELDS.facilityNpi]: [HCFA_FIELDS.facilityIdQuality, HCFA_FIELDS.facilityAddress, HCFA_FIELDS.facilityCity],
  [HCFA_FIELDS.facilityId]: [HCFA_FIELDS.facilityIdQuality],
  [HCFA_FIELDS.billingId]: [HCFA_FIELDS.billingNpi, HCFA_FIELDS.billingIdQuality],
  [HCFA_FIELDS.icdInd]: [
    HCFA_FIELDS.dxCodeA,
    HCFA_FIELDS.dxCodeB,
    HCFA_FIELDS.dxCodeC,
    HCFA_FIELDS.dxCodeD,
    HCFA_FIELDS.dxCodeE,
    HCFA_FIELDS.dxCodeF,
    HCFA_FIELDS.dxCodeG,
    HCFA_FIELDS.dxCodeH,
    HCFA_FIELDS.dxCodeI,
    HCFA_FIELDS.dxCodeJ,
    HCFA_FIELDS.dxCodeK,
    HCFA_FIELDS.dxCodeL
  ],
  [HCFA_FIELDS.billingIdQuality]: [],
  [HCFA_FIELDS.hcfaAmbFromLocation]: [HCFA_FIELDS.hcfaFromState],
  [HCFA_FIELDS.hcfaAmbToLocation]: [HCFA_FIELDS.hcfaToState],
  [HCFA_FIELDS.numberType]: [HCFA_FIELDS.authorizationNumber],
  [HCFA_FIELDS.facilityName]: [HCFA_FIELDS.facilityState],

  [HCFA_FIELDS.facilityCity]: [HCFA_FIELDS.facilityAddress],
  [HCFA_FIELDS.facilityZip]: [HCFA_FIELDS.facilityAddress],
  [HCFA_FIELDS.facilityState]: [HCFA_FIELDS.facilityAddress],
  [HCFA_FIELDS.facilityAddress]: [HCFA_FIELDS.facilityCity, HCFA_FIELDS.facilityZip, HCFA_FIELDS.facilityState],

  [HCFA_FIELDS.hcfaFromCity]: [HCFA_FIELDS.hcfaFromAddress],
  [HCFA_FIELDS.hcfaFromState]: [HCFA_FIELDS.hcfaFromAddress],
  [HCFA_FIELDS.hcfaFromZip]: [HCFA_FIELDS.hcfaFromAddress],
  [HCFA_FIELDS.hcfaFromAddress]: [HCFA_FIELDS.hcfaFromCity, HCFA_FIELDS.hcfaFromState, HCFA_FIELDS.hcfaFromZip],

  [HCFA_FIELDS.hcfaToCity]: [HCFA_FIELDS.hcfaToAddress],
  [HCFA_FIELDS.hcfaToState]: [HCFA_FIELDS.hcfaToAddress],
  [HCFA_FIELDS.hcfaToZip]: [HCFA_FIELDS.hcfaToAddress],
  [HCFA_FIELDS.hcfaToAddress]: [HCFA_FIELDS.hcfaToCity, HCFA_FIELDS.hcfaToState, HCFA_FIELDS.hcfaToZip],

  [HCFA_FIELDS.billingCity]: [HCFA_FIELDS.billingAddress],
  [HCFA_FIELDS.billingState]: [HCFA_FIELDS.billingAddress],
  [HCFA_FIELDS.billingZip]: [HCFA_FIELDS.billingAddress],
  [HCFA_FIELDS.billingAddress]: [HCFA_FIELDS.billingCity, HCFA_FIELDS.billingState, HCFA_FIELDS.billingZip],
  [HCFA_FIELDS.patientState]: [HCFA_FIELDS.insuredId, HCFA_FIELDS.billingAddress],
  [HCFA_FIELDS.insuredState]: [HCFA_FIELDS.insuredId, HCFA_FIELDS.billingAddress]
};

const ValidationTableDependenciesMap = {
  '24j_render_id': ['24g_units', '24i_render_id_qual'],
  '24a_dos_from': ['24a_dos_to'],
  '24d_procedure': ['24d_modifier_1', '24d_modifier_2', '24d_modifier_3', '24d_modifier_4'],
  '24d_modifier_1': ['24d_modifier_2', '24d_modifier_3', '24d_modifier_4'],
  '24d_modifier_2': ['24d_modifier_1', '24d_modifier_3', '24d_modifier_4'],
  '24d_modifier_3': ['24d_modifier_1', '24d_modifier_2', '24d_modifier_4'],
  '24d_modifier_4': ['24d_modifier_1', '24d_modifier_2', '24d_modifier_3'],
  [HCFA_FIELDS.dxCodeA]: [HCFA_FIELDS.diagnosisPointer],
  [HCFA_FIELDS.dxCodeB]: [HCFA_FIELDS.diagnosisPointer],
  [HCFA_FIELDS.dxCodeC]: [HCFA_FIELDS.diagnosisPointer],
  [HCFA_FIELDS.dxCodeD]: [HCFA_FIELDS.diagnosisPointer],
  [HCFA_FIELDS.dxCodeE]: [HCFA_FIELDS.diagnosisPointer],
  [HCFA_FIELDS.dxCodeF]: [HCFA_FIELDS.diagnosisPointer],
  [HCFA_FIELDS.dxCodeG]: [HCFA_FIELDS.diagnosisPointer],
  [HCFA_FIELDS.dxCodeH]: [HCFA_FIELDS.diagnosisPointer],
  [HCFA_FIELDS.dxCodeI]: [HCFA_FIELDS.diagnosisPointer],
  [HCFA_FIELDS.dxCodeJ]: [HCFA_FIELDS.diagnosisPointer],
  [HCFA_FIELDS.dxCodeK]: [HCFA_FIELDS.diagnosisPointer],
  [HCFA_FIELDS.dxCodeL]: [HCFA_FIELDS.diagnosisPointer],
  [HCFA_FIELDS.ndcCode]: [HCFA_FIELDS.ndcUnit, HCFA_FIELDS.ndcQuantity],
  [HCFA_FIELDS.procedure24]: [HCFA_FIELDS.anesthesiaDuration],
  '24i_render_id_qual': ['24j_render_id']
};
/**get index for 24Table[index].fieldName */
function extractIndexFor24TableYup(inputString: string): number | null {
  const regexPattern = /24Table\[(\d+)\]\.\w+/;

  const match = inputString.match(regexPattern);

  if (match && match[1]) {
    return parseInt(match[1], 10);
  } else {
    return null;
  }
}
/**get index for 24Table.index.fieldName */
function extractIndexFor24TableAntd(modelString: string): number | null {
  const regex = /^24Table\.(\d+)\..+$/;
  const match = modelString.match(regex);

  if (match && match[1]) {
    const index = parseInt(match[1], 10);
    return isNaN(index) ? null : index;
  }

  return null;
}

function getFormValues(context: ContextType) {
  const data = getFormProvidersDataFromGlobalObject();
  return data ? data : context.parent;
}

function testRelationRegardingPatientInsured(value: ValueType, context: ContextType) {
  if (value !== 'Self') return true;
  const formValues = getFormValues(context);
  const patFirstName = formValues[HCFA_FIELDS.patientFirstName];
  const patlastName = formValues[HCFA_FIELDS.patientLastName];
  const patDOB = formValues['3_pat_dob'];
  const patSex = formValues[HCFA_FIELDS.patientSex];
  const insFirstName = formValues[HCFA_FIELDS.insuredFirstName];
  const inslastName = formValues[HCFA_FIELDS.insuredLastName];
  const insDOB = formValues[HCFA_FIELDS.insuredDateOfBirth];
  const insSex = formValues[HCFA_FIELDS.insuredSex];

  return (
    patFirstName == insFirstName &&
    patlastName == inslastName &&
    patDOB?.toString() == insDOB?.toString() &&
    patSex == insSex
  );
}

function testPatientInsuredRegardingRelation(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[HCFA_FIELDS.patRelInfo6];
  if (!relationValue) return true;
  if (relationValue === 'Self' && !value) {
    return false;
  }

  return true;
}

function testAccidentStateWhenAutoAccident(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const autoAccidentValue = formValues[HCFA_FIELDS.autoAccident];
  if (autoAccidentValue && typeof autoAccidentValue === 'string' && autoAccidentValue.toLowerCase() === 'yes' && !value)
    return false;
  return true;
}

function testCurrentDate14ToAccidentAndQualifier(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const otherAccidentValue = formValues[HCFA_FIELDS.otherAccident];
  const autoAccidentValue = formValues[HCFA_FIELDS.autoAccident];
  const isAutoAccidentYes =
    autoAccidentValue && typeof autoAccidentValue === 'string' && autoAccidentValue.toLowerCase() === 'yes';
  const isOtherAccidentYes =
    otherAccidentValue && typeof otherAccidentValue === 'string' && otherAccidentValue.toLowerCase() === 'yes';
  if (isAutoAccidentYes || isOtherAccidentYes) return Boolean(value);
  return true;
}

function testQualifierToCurrentDate(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);

  const currentDateValue = formValues[HCFA_FIELDS.currentIllness];
  if (currentDateValue) return Boolean(value);
  return true;
}

function testOtherDateToQualifier(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const qualifierValue = formValues[HCFA_FIELDS.otherQuality];
  if (qualifierValue) return Boolean(value);
  return true;
}

function testOtherQualifierToOtherDate(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const dateValue = formValues[HCFA_FIELDS.otherDate];
  if (dateValue) return Boolean(value);
  return true;
}

function testLabCharges(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const outsideLab = formValues[HCFA_FIELDS.outsideLab];
  if (outsideLab && typeof outsideLab === 'string' && outsideLab.toLowerCase() === 'yes') return Boolean(value);
  return true;
}

function testOriginalRefNumToRessubmission(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const ressValues = [6, 7, 8];
  const selectedRessValue = formValues[HCFA_FIELDS.resubmissionCode];
  const numSelectedResValue = parseInt(selectedRessValue);
  if (isNaN(numSelectedResValue)) return true;
  if (ressValues.includes(numSelectedResValue)) return Boolean(value);
  return true;
}

function testTableUnits(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor24TableAntd(context.options.context?.path)
    : extractIndexFor24TableYup(context.path);
  if (index === null) return true;

  const tableValues = formValues['24Table'];
  if (!tableValues) return true;
  const row = tableValues[index];
  if (!row) return true;

  const ndcCodeValue = row[HCFA_FIELDS.ndcCode];

  if (ndcCodeValue) return Boolean(value);
  return true;
}

function testProviderNameToSupplier(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const physicianValue = formValues[HCFA_FIELDS.HCFA33psysiciancheckbox];
  const isSupplier =
    !physicianValue ||
    (typeof physicianValue === 'string' && physicianValue.toLowerCase() === HCFA33PhysicianEnum.supplier);

  if (!isSupplier) return true;
  return Boolean(value);
}

function testProviderNameToPhysician(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const physicianValue = formValues[HCFA_FIELDS.HCFA33psysiciancheckbox];
  const isPhysician =
    physicianValue &&
    typeof physicianValue === 'string' &&
    physicianValue.toLowerCase() === HCFA33PhysicianEnum.physician;
  if (!isPhysician) return true;
  return Boolean(value);
}

function testAmountPaidToTotal(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const totalCharges = formValues[HCFA_FIELDS.totalCharges];
  if (!totalCharges || !value) return true;
  const totalChargesParsed = parseFloat(totalCharges);
  const valueParsed = parseFloat(value);
  if (isNaN(totalChargesParsed) || isNaN(valueParsed)) return true;
  return valueParsed <= totalChargesParsed;
}

function testTotalChargeToTableFields(value: ValueType, context: ContextType) {
  if (!value) return true;
  const valueParsed = parseFloat(value);
  if (isNaN(valueParsed)) return true;
  const formValues = getFormValues(context);
  const tableValues: any[] = formValues['24Table'];
  if (!tableValues && !Array.isArray(tableValues)) return valueParsed == 0;
  let sum = 0;
  tableValues.forEach((row) => {
    const chargeValue = row?.[HCFA_FIELDS.chargesf24];
    if (chargeValue && !isNaN(parseFloat(chargeValue))) {
      sum += parseFloat(chargeValue);
    }
  });
  return valueParsed.toFixed(2) == sum.toFixed(2);
}

function testFutureDate(value: ValueType) {
  if (!value) return true;
  const date = moment(value);
  // console.log('date ', date, value);
  if (!date.isValid()) return true;
  const today = moment(Date.now()).endOf('day');
  return date.isSameOrBefore(today);
}

function testDOBRequired(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relInfo = formValues[HCFA_FIELDS.patRelInfo6];
  if (!relInfo) return true;
  if (relInfo !== 'Self' && !value) return false;
  return true;
}

function testDosFromDosToOneYear(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  if (!value) return true;
  const date = moment(value);
  if (!date.isValid()) return true;
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor24TableAntd(context.options.context?.path)
    : extractIndexFor24TableYup(context.path);
  if (index === null) return true;

  const tableValues = formValues['24Table'];
  if (!tableValues) return true;
  const row = tableValues[index];
  if (!row) return true;
  const fromDate = row?.['24a_dos_from'];
  if (!fromDate) return true;
  const fromDateMom = moment(fromDate).startOf('day');
  if (!fromDateMom.isValid()) return true;
  const diffInYears = date.diff(fromDateMom, 'years', true);
  return diffInYears < 1;
}

export const test100YearsPast = (value: ValueType) => {
  if (!value) return true;
  const _value = moment(value, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ', true);
  const oneHundredYearsAgo = moment().subtract(100, 'years');
  if (!_value.isValid()) return true;
  return _value.isSameOrAfter(oneHundredYearsAgo);
};

function testIsBeforeBirthDate(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  if (!value) return true;
  const date = moment(value);
  if (!date.isValid()) return true;
  const birthDate = formValues['3_pat_dob'];
  if (!birthDate) return true;
  const birthDateMom = moment(birthDate).startOf('day');
  if (!birthDateMom.isValid()) return true;
  return date.isSameOrAfter(birthDateMom);
}

function testDosFromToDiagnosisCodes(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);

  if (!value) return true;
  const date = moment(value);

  if (!date.isValid()) return true;
  const endOfOctober15 = moment('2015-10-31').endOf('day');

  const isAfter = date.isSameOrAfter(endOfOctober15);

  if (!isAfter) return true;
  const diagnosisCodes = Object.values(DIAGNOSIS_CODES_MAP).map((code) => formValues[code]);

  const hasInvalidCode = diagnosisCodes.some((code) => {
    return code && typeof code === 'string' && !isNaN(Number(code[0]));
  });

  return !hasInvalidCode;
}

function testIsToAfterFrom(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  if (!value) return true;
  const date = moment(value);
  if (!date.isValid()) return true;
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor24TableAntd(context.options.context?.path)
    : extractIndexFor24TableYup(context.path);
  if (index === null) return true;

  const tableValues = formValues['24Table'];
  if (!tableValues) return true;
  const row = tableValues[index];
  if (!row) return true;
  const fromDate = row?.['24a_dos_from'];
  if (!fromDate) return true;
  const fromDateMom = moment(fromDate).startOf('day');
  if (!fromDateMom.isValid()) return true;
  return date.isSameOrAfter(fromDateMom);
}

function testProviderIdToQual(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor24TableAntd(context.options.context?.path)
    : extractIndexFor24TableYup(context.path);
  if (index === null) return true;
  const tableValues = formValues['24Table'];
  if (!tableValues) return true;
  const row = tableValues[index];
  const qualValue = row?.['24i_render_id_qual'];
  if (qualValue) return Boolean(value);
  return true;
}

function testModifiersToCPT1(value: ValueType, context: ContextType) {
  return testModifiers(value, context, ['24d_modifier_2', '24d_modifier_3', '24d_modifier_4']);
}
function testModifiersToCPT2(value: ValueType, context: ContextType) {
  return testModifiers(value, context, ['24d_modifier_1', '24d_modifier_3', '24d_modifier_4']);
}
function testModifiersToCPT3(value: ValueType, context: ContextType) {
  return testModifiers(value, context, ['24d_modifier_1', '24d_modifier_2', '24d_modifier_4']);
}
function testModifiersToCPT4(value: ValueType, context: ContextType) {
  return testModifiers(value, context, ['24d_modifier_1', '24d_modifier_2', '24d_modifier_3']);
}

function testModifiers(value: ValueType, context: ContextType, toTest: [string, string, string]) {
  const formValues = getFormValues(context);
  if (!value) return true;
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor24TableAntd(context.options.context?.path)
    : extractIndexFor24TableYup(context.path);
  if (index === null) return true;
  const tableValues = formValues['24Table'];
  if (!tableValues) return true;
  const row = tableValues[index];
  if (!row) return true;
  const cptValue = row['24d_procedure'];
  if (!cptValue) return true;
  const hasDuplicate = toTest.some((path) => row[path] && row[path] == value);
  return !hasDuplicate;
}

function testNPIforLuhnAlghoritm(value: ValueType, context: ContextType) {
  if (!value) return true;
  return isValidLuhn(value);
}
function testNIPIsRequired(value: ValueType, context: ContextType) {
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor24TableAntd(context.options.context?.path)
    : extractIndexFor24TableYup(context.path);
  if (index !== 0) return true;
  const formValues = getFormValues(context);
  const physicianFirstName = formValues[HCFA_FIELDS.physicianFirstName];
  const physicianLastName = formValues[HCFA_FIELDS.physicianLastName];
  if (physicianFirstName || physicianLastName) return Boolean(value);
  return true;
}

function testIndividualCharge(value: ValueType) {
  if (!value) return true;
  const floatValue = parseFloat(value);
  if (isNaN(floatValue)) return true;
  return floatValue <= 15000;
}

function testIndividualInsuredAddressFieldsToAddress(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const insuredAddress = formValues[HCFA_FIELDS.insuredAddress];
  if (!insuredAddress) return true;
  return Boolean(value);
}

function testDiagnosisIllnesAndPointer(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);

  if (!value) return true;
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor24TableAntd(context.options.context?.path)
    : extractIndexFor24TableYup(context.path);
  if (index === null) return true;

  const diagIllnesA = formValues[HCFA_FIELDS.dxCodeA];
  const diagIllnesB = formValues[HCFA_FIELDS.dxCodeB];
  const diagIllnesC = formValues[HCFA_FIELDS.dxCodeC];
  const diagIllnesD = formValues[HCFA_FIELDS.dxCodeD];
  const diagIllnesE = formValues[HCFA_FIELDS.dxCodeE];
  const diagIllnesF = formValues[HCFA_FIELDS.dxCodeF];
  const diagIllnesG = formValues[HCFA_FIELDS.dxCodeG];
  const diagIllnesH = formValues[HCFA_FIELDS.dxCodeH];
  const diagIllnesI = formValues[HCFA_FIELDS.dxCodeI];
  const diagIllnesJ = formValues[HCFA_FIELDS.dxCodeJ];
  const diagIllnesK = formValues[HCFA_FIELDS.dxCodeK];
  const diagIllnesL = formValues[HCFA_FIELDS.dxCodeL];

  const tableValues = formValues['24Table'];
  if (!tableValues) return true;
  const row = tableValues[index];

  if (!row) return true;

  const diagPointer = row[HCFA_FIELDS.diagnosisPointer];

  if (
    (!diagIllnesA && diagPointer.includes('A')) ||
    (!diagIllnesB && diagPointer.includes('B')) ||
    (!diagIllnesC && diagPointer.includes('C')) ||
    (!diagIllnesD && diagPointer.includes('D')) ||
    (!diagIllnesE && diagPointer.includes('E')) ||
    (!diagIllnesF && diagPointer.includes('F')) ||
    (!diagIllnesG && diagPointer.includes('G')) ||
    (!diagIllnesH && diagPointer.includes('H')) ||
    (!diagIllnesI && diagPointer.includes('I')) ||
    (!diagIllnesJ && diagPointer.includes('J')) ||
    (!diagIllnesK && diagPointer.includes('K')) ||
    (!diagIllnesL && diagPointer.includes('L'))
  ) {
    return false;
  }

  if (diagPointer) {
    if (/^(1|A)$/.test(diagPointer) && !diagIllnesA) {
      return false;
    } else if (/^(B|2)$/.test(diagPointer) && !diagIllnesB) {
      return false;
    } else if (/^(C|3)$/.test(diagPointer) && !diagIllnesC) {
      return false;
    } else if (/^(D|4)$/.test(diagPointer) && !diagIllnesD) {
      return false;
    } else if (/^(E|5)$/.test(diagPointer) && !diagIllnesE) {
      return false;
    } else if (/^(F|6)$/.test(diagPointer) && !diagIllnesF) {
      return false;
    } else if (/^(G|7)$/.test(diagPointer) && !diagIllnesG) {
      return false;
    } else if (/^(H|8)$/.test(diagPointer) && !diagIllnesH) {
      return false;
    } else if (/^(I|9)$/.test(diagPointer) && !diagIllnesI) {
      return false;
    } else if (/^(J|10)$/.test(diagPointer) && !diagIllnesJ) {
      return false;
    } else if (/^(K|11)$/.test(diagPointer) && !diagIllnesK) {
      return false;
    } else if (/^(L|12)$/.test(diagPointer) && !diagIllnesL) {
      return false;
    } else {
      return true;
    }
  }

  // if (!diagIllnesA) return true;
  return Boolean(value);
}

function testHospitalizationToAfterFrom(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  if (!value) return true;
  const date = moment(value);
  if (!date.isValid()) return true;
  const fromHospitalization = formValues[HCFA_FIELDS.hospitalizationFromDate];
  if (!fromHospitalization) return true;
  const fromHospitalizationMom = moment(fromHospitalization).startOf('day');
  if (!fromHospitalizationMom.isValid()) return true;
  return date.isSameOrAfter(fromHospitalizationMom);
}

function testUnableToWorkToAfterFrom(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  if (!value) return true;
  const date = moment(value);
  if (!date.isValid()) return true;
  const from = formValues[HCFA_FIELDS.unableToWorkFromDate];
  if (!from) return true;
  const fromMom = moment(from).startOf('day');
  if (!fromMom.isValid()) return true;
  return date.isSameOrAfter(fromMom);
}

function testSignature(value: ValueType) {
  if (typeof value === 'string') return true;
  return false;
}

function testCurrentIllnessBeforeDOSFrom(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  if (!value) return true;
  const date = moment(value).endOf('day');
  if (!date.isValid()) return true;
  // const qual = formValues[HCFA_FIELDS.quality];
  // if (qual != 431) return true;
  const tableValues = formValues['24Table'];
  if (!tableValues || !tableValues?.length) return true;
  return tableValues.every((v: any) => {
    const dosFrom = v?.['24a_dos_from'];
    if (!dosFrom) return true;
    const dosFromMom = moment(dosFrom).endOf('day');
    if (!dosFromMom.isValid()) return true;
    return date.isSameOrBefore(dosFromMom);
  });
}

function testOtherQualityToBox10(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const autoAccident = formValues[HCFA_FIELDS.autoAccident];
  const otherAccident = formValues[HCFA_FIELDS.otherAccident];
  const employmentStatus = formValues[HCFA_FIELDS.employmentStatus];
  // const otherDate15 = formValues[HCFA_FIELDS.otherDate];

  if (value === '439') return true;
  // if (otherDate15) {
  if (typeof autoAccident === 'string' && autoAccident?.toLowerCase() === 'yes') return false;
  if (typeof otherAccident === 'string' && otherAccident?.toLowerCase() === 'yes') return false;
  if (typeof employmentStatus === 'string' && employmentStatus?.toLowerCase() === 'yes') return false;
  // }
  return true;
}

function testOtherDateToBOx10(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const autoAccident = formValues[HCFA_FIELDS.autoAccident];
  const otherAccident = formValues[HCFA_FIELDS.otherAccident];
  const employmentStatus = formValues[HCFA_FIELDS.employmentStatus];
  const otherQuality = formValues[HCFA_FIELDS.otherQuality];
  if (!value) return true;
  if (otherQuality !== '439') return true;
  if (
    (typeof autoAccident === 'string' && autoAccident?.toLowerCase() == 'yes') ||
    (typeof otherAccident === 'string' && otherAccident?.toLowerCase() == 'yes') ||
    (typeof employmentStatus === 'string' && employmentStatus?.toLowerCase() == 'yes')
  )
    return true;
  return false;
}

function testOtherDateToBOx10IsValid(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const autoAccident = formValues[HCFA_FIELDS.autoAccident];
  const otherAccident = formValues[HCFA_FIELDS.otherAccident];
  const employmentStatus = formValues[HCFA_FIELDS.employmentStatus];

  if (
    (typeof autoAccident === 'string' && autoAccident?.toLowerCase() == 'yes') ||
    (typeof otherAccident === 'string' && otherAccident?.toLowerCase() == 'yes') ||
    (typeof employmentStatus === 'string' && employmentStatus?.toLowerCase() == 'yes')
  ) {
    const date = moment(value);
    const today = moment(Date.now()).endOf('day');
    const isAfterToday = date.isSameOrAfter(today);
    if (!value || !date.isValid() || isAfterToday) return false;
  }
  return true;
}

function testFacilityStateToName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const nameValue = formValues[HCFA_FIELDS.facilityName];
  const addressValue = formValues[HCFA_FIELDS.facilityAddress];
  if (nameValue) return Boolean(value);
  if (addressValue) return Boolean(value);
  return true;
}

function testOtherDateBeforeDOSFrom(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  if (!value) return true;
  const date = moment(value).endOf('day');
  if (!date.isValid()) return true;

  const tableValues = formValues['24Table'];
  if (!tableValues || !tableValues?.length) return true;
  return tableValues.every((v: any) => {
    const dosFrom = v?.['24a_dos_from'];
    if (!dosFrom) return true;
    const dosFromMom = moment(dosFrom).endOf('day');
    if (!dosFromMom.isValid()) return true;
    return date.isSameOrBefore(dosFromMom);
  });
}

function testRefferingProviderFirstLastNameToNPIId(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const referringNPI = formValues[HCFA_FIELDS.referringNpi];
  const providerId = formValues[HCFA_FIELDS.referringProviderId];
  if (referringNPI || providerId) return Boolean(value);
  return true;
}

function testRefferingNPIToRefferingFirstLastName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const firstName = formValues[HCFA_FIELDS.referringProviderFirstName];
  const lastName = formValues[HCFA_FIELDS.referringProviderLastName];

  if (firstName || lastName) {
    return Boolean(value);
  }

  return true;
}

function testIsPositive(value: ValueType) {
  if (!value) return true;
  const parsed = parseFloat(value);
  if (isNaN(parsed)) return true;
  return parsed >= 1;
}

function testQualifierToRenderingProviderIdInTable(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  if (!value) return true;
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor24TableAntd(context.options.context?.path)
    : extractIndexFor24TableYup(context.path);
  if (index === null) return true;

  const tableValues = formValues['24Table'];
  if (!tableValues) return true;
  const row = tableValues[index];
  if (!row) return true;
  const providerId = row[HCFA_FIELDS.renderId24];
  if (providerId) return true;
  return false;
}

function testAmbulanceFromStateLocation(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const isAmbulance = formValues[HCFA_FIELDS.isAmbulance];
  if (!isAmbulance) return true;
  const fromLocation = formValues[HCFA_FIELDS.hcfaAmbFromLocation];

  if (fromLocation) return Boolean(value);
  return true;
}

function testAmbulanceToStateLocation(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const isAmbulance = formValues[HCFA_FIELDS.isAmbulance];
  if (!isAmbulance) return true;
  const toLocation = formValues[HCFA_FIELDS.hcfaAmbToLocation];
  if (toLocation) return Boolean(value);
  return true;
}

function testIsValidCurrency(value: ValueType) {
  if (!value) return true;
  const parsedValue = parseFloat(value);
  if (isNaN(parsedValue)) return false;
  const [_, decimals] = value.split('.');
  return decimals.length === 2;
}

function testNameToNPI32Supplier(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const physician = formValues[HCFA_FIELDS.HCFA33psysiciancheckbox];
  if (physician == HCFA33PhysicianEnum.physician) return true;
  const npi = formValues[HCFA_FIELDS.billingNpi];
  if (npi) return Boolean(value);
  return true;
}

function testNameToNPI32Physician(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const physician = formValues[HCFA_FIELDS.HCFA33psysiciancheckbox];
  if (physician !== HCFA33PhysicianEnum.physician) return true;
  const npi = formValues[HCFA_FIELDS.billingNpi];
  if (npi) return Boolean(value);
  return true;
}

function testAddressToNPI32(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);

  const npi = formValues[HCFA_FIELDS.billingNpi];
  if (npi) return Boolean(value);
  return true;
}

function testFacilityIdQualityToNPIandId(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);

  const npi = formValues[HCFA_FIELDS.facilityNpi];
  const facilityId = formValues[HCFA_FIELDS.facilityId];
  if (!npi && !facilityId) return !value;
  return true;
}

function testFacilityAddressAndCityToFacilityNPI(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const npi = formValues[HCFA_FIELDS.facilityNpi];
  if (npi) return Boolean(value);
  return true;
}

function testBillingIdQualityToNPIandId(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);

  const npi = formValues[HCFA_FIELDS.billingNpi];
  const billingId = formValues[HCFA_FIELDS.billingId];
  if (!npi && !billingId) return !value;
  return true;
}

function testBillingIdQualityToNPIandIdIfOnlyId(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);

  const npi = formValues[HCFA_FIELDS.billingNpi];
  const billingId = formValues[HCFA_FIELDS.billingId];
  if (!npi && billingId) return Boolean(value);
  return true;
}

function testIsBillingIdAndIdBlank(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const npi = formValues[HCFA_FIELDS.billingNpi];
  const id = formValues[HCFA_FIELDS.billingId];
  if (!npi && !id) return false;
  return true;
}

function testBillingPinToQual(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const qual = formValues[HCFA_FIELDS.billingIdQuality];
  if (qual) return Boolean(value);
  return true;
}

// function test100YearsOld(value: ValueType) {
//   if (!value) return true;
//   const date = moment(value);
//   if (!date.isValid()) return true;
//   const centuryBefore = moment(Date.now()).endOf('day').subtract(100, 'years');
//   return date.isSameOrAfter(centuryBefore);
// }

function testPhysicalAddress(value: ValueType, context: ContextType) {
  if (!value || typeof value !== 'string') return true;
  const excludedStates = ['in', 'ky', 'ga', 'wv'];
  // const lob = sessionStorage.getItem('lob');
  const formValues = getFormValues(context);
  const patientstate = formValues[HCFA_FIELDS.patientState];
  const state = formValues[HCFA_FIELDS.insuredState];
  if (
    // (lob && POBOXLobStates.includes(lob.toLowerCase())) ||
    (patientstate && !excludedStates.includes(patientstate.toLowerCase())) ||
    (state && !excludedStates.includes(state.toLowerCase()))
  ) {
    // const isPOBox = POBoxAddressPartials.some((p) => value.toLowerCase().includes(p));
    const isPOBox = checkIsPostBox(value);
    const formValues = getFormValues(context);
    const addressTwo = formValues[HCFA_FIELDS.HCFA33Address2];
    if (isPOBox && !addressTwo) return false;
  }
  return true;
}
function testPhysicalAddressRequired(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const addressTwo = formValues[HCFA_FIELDS.HCFA33Address2];
  if (addressTwo) return true;
  if (!value) return false;
  return true;
}

async function diagnosisCodeLookup(value: ValueType) {
  if (!value) return true;
  if (value.length < 3 || value.length > 7) return true;
  const token = getTokenFromSessionStorage();
  try {
    const res = await dxLookup(value.replace(/\./g, ''), token);
    return res.isValid;
  } catch (e) {
    return false;
  }
}

async function zipCodeLookup(value: ValueType, context: ContextType) {
  if (!value) return true;
  if (value.length !== 5 && value.length !== 9) return true;

  const token = getTokenFromSessionStorage();
  try {
    const res = await zipLookup({ city: '', state: '', zip: value }, token);

    return Boolean(res && res.length);
  } catch (e) {
    return false;
  }
}

async function testAuthorizationNumber(value: ValueType, context: ContextType) {
  if (!value) return true;
  const formValues = getFormValues(context);
  const numberType = formValues[HCFA_FIELDS.numberType];
  if (numberType === HCFA23NumberType.priorAuthnumber) {
    if (!alphaNum.test(value))
      return context.createError({
        message: 'Value entered is not valid, please re-enter correct value [A-Z and 0-9]',
        path: context.path
      });
    if (value.length > 50)
      return context.createError({
        message: 'Field does not meet length requirement, please re-enter correct value [1-50]',
        path: context.path
      });
  }
  return true;
}

async function testProcedureCodeLookup(value: ValueType) {
  if (!value) return true;
  if (value.length !== 5) return true;
  const token = getTokenFromSessionStorage();
  try {
    const res = await procedureLookup(value, token);
    return res.isValid;
  } catch (e) {
    return false;
  }
}

function testInsuredIdNumeric(value: ValueType, context: ContextType) {
  if (!value) return true;
  const numericRegex = /^[0-9]*$/;

  const lob = sessionStorage.getItem('lob');
  if (lob && lob.toLowerCase() === 'wi') return true;
  const formValues = getFormValues(context);
  const patientState = formValues[HCFA_FIELDS.patientState];

  if (patientState !== 'MI' && !numericRegex.test(value as string)) return false;
  return true;
}
function testInsuredIdAlphanumeric(value: ValueType, context: ContextType) {
  if (!value) return true;
  const lob = sessionStorage.getItem('lob');
  if (lob && lob.toLowerCase() === 'wi') return true;
  const alphaNumStartsEndsWithNumber = /^[0-9]+[0-9a-zA-Z]*[0-9]+$/;

  const formValues = getFormValues(context);
  const patientState = formValues[HCFA_FIELDS.patientState];
  if (patientState !== 'MI') return true;
  if (patientState === 'MI' && !alphaNumStartsEndsWithNumber.test(value as string)) {
    return false;
  }
  return true;
}

function testInsuredIdIfLobIw(value: ValueType, context: ContextType) {
  if (!value) return true;
  const lob = sessionStorage.getItem('lob');

  if (!lob) return true;
  if (lob && lob.toLowerCase() !== 'wi') return true;
  // lob is IW
  const isAlphaNumWithHyphens = aphaNumWithHyphensRegex.test(value);
  if (!isAlphaNumWithHyphens) return false;
  return value.length >= 9 && value.length <= 15;
}

function testInsuredIdLengthIfLobNotIw(value: ValueType, context: ContextType) {
  if (!value) return true;
  const lob = sessionStorage.getItem('lob');
  if (lob && lob.toLowerCase() === 'wi') return true;
  return value.length >= 5 && value.length <= 15;
}

// function testInsuredIdAlphanumericLenght(value: ValueType, context: ContextType) {
//   const formValues = getFormValues(context);
//   const patientState = formValues[HCFA_FIELDS.patientState];

//   if (patientState === 'MI' && value) {
//     if (/[a-zA-Z]/.test(value)) {
//       if (/^[0-9]/.test(value) && /[0-9]$/.test(value)) {
//         return true;
//       } else {
//         return false;
//       }
//     } else {
//       return true;
//     }
//   }
//   return true;
// }

function testIsDateValid(value: ValueType) {
  if (!value) return true;

  return value !== 'Invalid date';
}

function testAnesthesiaDuration(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor24TableAntd(context.options.context?.path)
    : extractIndexFor24TableYup(context.path);
  if (index === null) return true;
  const tableValues = formValues['24Table'];
  if (!tableValues) return true;
  const row = tableValues[index];
  if (!row) return true;
  const procedure24 = row[HCFA_FIELDS.procedure24];
  if (!procedure24) return true;
  const prodecureCodeNumber = parseInt(procedure24);
  if (!prodecureCodeNumber) return true;
  if (prodecureCodeNumber >= 100 && prodecureCodeNumber <= 1999 && !value) {
    return false;
  }
  return true;
}

function testFullAddressDependency(value: ValueType, context: ContextType, addressDependency: any) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[addressDependency];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testAmb001(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const table24Service = formValues['24Table'];
  const isAmbulance = formValues[HCFA_FIELDS.isAmbulance];

  if (!table24Service) return true;

  for (const obj of table24Service) {
    if (
      obj &&
      (obj['24b_place_of_service'] === '41' || obj['24b_place_of_service'] === '42') &&
      isAmbulance &&
      !value
    ) {
      return false;
    }
  }

  return true;
}

function testAmb004(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const table24Service = formValues['24Table'];
  const isAmbulance = formValues[HCFA_FIELDS.isAmbulance];

  if (!table24Service) return true;

  for (const obj of table24Service) {
    if (obj) {
      let placeOfService = obj[HCFA_FIELDS.procedure24];
      if (
        placeOfService === 'A0225' ||
        placeOfService === 'S0209' ||
        placeOfService === 'T2049' ||
        placeOfService === 'A0435' ||
        placeOfService === 'A0436' ||
        placeOfService === 'A0425' ||
        (placeOfService >= 'A0100' && placeOfService <= 'A0140') ||
        (placeOfService >= 'A0426' && placeOfService <= 'A0434') ||
        (placeOfService >= 'A0998' && placeOfService <= 'A0999') ||
        (placeOfService >= 'T2001' && placeOfService <= 'T2005')
      ) {
        return true;
      } else {
        if (!value && isAmbulance) {
          return false;
        }
      }
    }
  }

  return true;
}

function testZipCode5or9IgnoreHyphens(value: ValueType) {
  if (!value) return true;
  if (typeof value !== 'string') return true;
  if (value.startsWith('-')) return false;
  if (value.endsWith('-')) return false;
  if (value.includes('--')) return false;
  const cleanValue = value.replace(/-/g, '');
  return numericLengthFiveOrNine.test(cleanValue);
}

export {
  testPatientInsuredRegardingRelation,
  testRelationRegardingPatientInsured,
  ValidationsDependenciesMap,
  testAccidentStateWhenAutoAccident,
  testCurrentDate14ToAccidentAndQualifier,
  testQualifierToCurrentDate,
  testOtherDateToQualifier,
  testOtherQualifierToOtherDate,
  testLabCharges,
  testOriginalRefNumToRessubmission,
  testTableUnits,
  testProviderNameToSupplier,
  testProviderNameToPhysician,
  testAmountPaidToTotal,
  testTotalChargeToTableFields,
  testFutureDate,
  testIsBeforeBirthDate,
  testIsToAfterFrom,
  ValidationTableDependenciesMap,
  testModifiersToCPT1,
  testModifiersToCPT2,
  testModifiersToCPT3,
  testModifiersToCPT4,
  testNPIforLuhnAlghoritm,
  testIndividualCharge,
  testIndividualInsuredAddressFieldsToAddress,
  testDiagnosisIllnesAndPointer,
  testHospitalizationToAfterFrom,
  testSignature,
  testCurrentIllnessBeforeDOSFrom,
  testOtherQualityToBox10,
  testOtherDateToBOx10,
  testOtherDateToBOx10IsValid,
  testOtherDateBeforeDOSFrom,
  testRefferingProviderFirstLastNameToNPIId,
  testRefferingNPIToRefferingFirstLastName,
  testIsPositive,
  testQualifierToRenderingProviderIdInTable,
  testIsValidCurrency,
  testNameToNPI32Supplier,
  testNameToNPI32Physician,
  testAddressToNPI32,
  testFacilityIdQualityToNPIandId,
  testIsBillingIdAndIdBlank,
  testAmbulanceFromStateLocation,
  testAmbulanceToStateLocation,
  testInsuredIdIfLobIw,
  testInsuredIdLengthIfLobNotIw,
  testProviderIdToQual,
  testDosFromToDiagnosisCodes,
  testDosFromDosToOneYear,
  // test100YearsOld,
  testBillingIdQualityToNPIandId,
  testBillingIdQualityToNPIandIdIfOnlyId,
  testBillingPinToQual,
  testPhysicalAddress,
  diagnosisCodeLookup,
  zipCodeLookup,
  testAuthorizationNumber,
  testProcedureCodeLookup,
  testFacilityAddressAndCityToFacilityNPI,
  testFacilityStateToName,
  testUnableToWorkToAfterFrom,
  testInsuredIdNumeric,
  testInsuredIdAlphanumeric,
  // testInsuredIdAlphanumericLenght,
  testNIPIsRequired,
  testFullAddressDependency,
  testDOBRequired,
  testPhysicalAddressRequired,
  testAmb001,
  testAmb004,
  testIsDateValid,
  testAnesthesiaDuration,
  testZipCode5or9IgnoreHyphens
};
