import FormErrorList from 'components/hcfa/shared/FormErrorList';
import { FormContext } from '../common/FormProvider';
import { FORM_ACTIONS, SECTIONS } from 'config/constants';
import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select, Col } from 'antd';
import {
  ADDITIONAL_CODES,
  ADMISSION_SOURCE,
  ADMISSION_TYPE,
  HOURS,
  UB04_FIELDS,
  INSURED,
  OTHER_PROVIDERS
} from 'utils/ub04';
import { getYupValidator, getYupWarning } from 'utils/ub04/validataions';
import FieldsetLocation from 'components/ub04/shared/FieldsetLocation';
import FieldsetProviderIds from './shared/FieldsetProviderIds';
import FieldsetFullName from 'components/ub04/shared/FieldsetFullName';
import { DATE_FORMAT } from 'utils/hcfaConstants';
import FieldsetInsuredInformation from './shared/FieldsetInsuredInfromation';
import FieldsetPayerInformation from './shared/FieldsetPayerInfromation';
import SelectState from 'components/hcfa/shared/SelectState';
import FieldsetOccurrence from './shared/FieldsetOcurrence';
import FieldsetSpanOccurrence from './shared/FieldsetSpanOcurrence';
import FieldsetValueCode from './shared/FieldsetValueCode';
import ServicesFieldsList from './ServicesFieldsList';
import FieldsetTreatmentInformation from './shared/FieldsetTreatmentInformation';
import FormItemSex from './shared/FormItemSex';
import FieldsetDiagnosisCode from './shared/FieldsetDiagnosisCode';
import FieldsetECICode from './shared/FieldsetECICode';
// import DestinationPayer from './shared/DestinationPayer';
import { toPositiveNumber } from 'helpers/toPositiveNumber';
import { useParams, usePrompt } from 'react-router-dom';
import { InfoCircleOutlined } from '@ant-design/icons';
import { getRevalidateFields, revalidateTable } from 'helpers/revalidateHcfaTable';
import FieldsetAdditionalCodes from './shared/FieldsetAdditionalCodes';
import { toAlphaNumUppercase } from 'helpers/toAlphaNumUppercase';
import { onSearchSelect } from 'utils/search/search';
import DatePickerAutoPopulate from 'components/common/DatePickerAutoPopulate';
import TextArea from 'antd/lib/input/TextArea';
import { useAccessTokenProvidedInfo } from 'utils/hooks/useAccessTokenProvidedInfo';
import { getFormProvidersDataFromGlobalObject } from 'utils/hcfa/putFormProvidersDataInGlobalObject';
import { useHotkeys } from 'react-hotkeys-hook';

const PaperFormUb: React.FC<{ section: SECTIONS }> = ({ section }) => {
  const { t } = useTranslation();
  const {
    form,

    setData,
    errorInfo,
    fullErrorInfo,
    isFormDirty,
    setIsFormDirty,
    // firstValidationPerformed,
    fullWarningInfo,
    revalidateForm,
    handleQuickSave
  } = useContext(FormContext);
  const { action } = useParams();

  const {
    // LobOidc,
    npiOidc,
    providerAddressOidc,
    providerAddressOtherOidc,
    providerCityOidc,
    providerNameOidc,
    providerStateOidc,
    providerZipCodeOidc,
    taxID
    // userAddressOidc,
    // userBillingOidc
  } = useAccessTokenProvidedInfo();
  const isNew = action === FORM_ACTIONS.NEW;
  const disabled = action === FORM_ACTIONS.VIEW;

  useHotkeys(
    'ctrl+s, command+s',
    (ev) => {
      ev.preventDefault();
      ev.stopPropagation();

      handleQuickSave('ub04');
    },
    { enableOnFormTags: true }
  );

  useEffect(() => {
    if (isNew) {
      const toSet = {
        [UB04_FIELDS.billingName]: providerNameOidc,
        [UB04_FIELDS.billingAddress]: providerAddressOidc,
        [UB04_FIELDS.billingNpi]: npiOidc,
        [UB04_FIELDS.billing_addr2]: providerAddressOtherOidc,
        [UB04_FIELDS.codeQualifier]: '0',
        [UB04_FIELDS.billingCity]: providerCityOidc,
        [UB04_FIELDS.billingState]: providerStateOidc,
        [UB04_FIELDS.billingZip]: providerZipCodeOidc,
        [UB04_FIELDS.federalTaxNumber]: taxID
      };

      setData({ ...getFormProvidersDataFromGlobalObject(), ...toSet });
    }
  }, []);

  const renderAdditionalCodes = (index: number) => {
    return (
      <Col key={ADDITIONAL_CODES[index]}>
        <FieldsetDiagnosisCode
          codeNameId={UB04_FIELDS[`code67${ADDITIONAL_CODES[index]}Dx`]}
          codeSelectId={UB04_FIELDS[`code67${ADDITIONAL_CODES[index]}Poa`]}
          labels={{
            codeName: ADDITIONAL_CODES[index],
            codeSelect: ADDITIONAL_CODES[index] + '  poa.'
          }}
          isPaperForm={true}
        />
      </Col>
    );
  };

  const renderReasonForVisit = (char: string) => {
    return (
      <Col key={char}>
        <Form.Item
          label={<span>&nbsp;</span>}
          // @ts-ignore
          name={UB04_FIELDS[`reasonForVisit${char.toUpperCase()}`]}
          rules={[getYupValidator(t, UB04_FIELDS[`reasonForVisit${char.toUpperCase()}`])]}
        >
          <Input
            placeholder={char.toUpperCase()}
            onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
          />
        </Form.Item>
      </Col>
    );
  };

  function handleFormChanges(changedValue: any) {
    // fillPayerNameIfEmpty(changedValue);
    const isFormTouched = form?.isFieldsTouched();
    isFormTouched && setIsFormDirty(isFormTouched);
    // if (!firstValidationPerformed) return;
    const [fieldName] = Object.keys(changedValue);
    if (fieldName === UB04_FIELDS.servicesList) {
      const tableChange = changedValue[UB04_FIELDS.servicesList];
      const changedRow = tableChange.findIndex(Boolean);
      if (changedRow === -1) return;
      const [changeFieldName] = Object.keys(tableChange[changedRow] as Record<string, string>);
      const tableFieldsToRevalidate = getRevalidateFields(changeFieldName, 'ub04');
      /**Custom */

      if (changeFieldName === UB04_FIELDS.dos_45) {
        form?.validateFields([UB04_FIELDS.coversPeriodFrom, UB04_FIELDS.coversPeriodTo]);
      }
      /************************************ */
      if (tableFieldsToRevalidate.length)
        revalidateTable({
          row: changedRow,
          fields: tableFieldsToRevalidate,
          formInstance: form!,
          tableName: '42Services'
        });
    } else if (fieldName) {
      if (
        fieldName === UB04_FIELDS.patientBirthdate ||
        fieldName === UB04_FIELDS.coversPeriodFrom ||
        fieldName === UB04_FIELDS.coversPeriodTo
      ) {
        revalidateTable({
          fields: [UB04_FIELDS.dos_45],
          formInstance: form!,
          tableName: '42Services'
        });
      }

      revalidateForm(fieldName, 'ub04');
    }
  }

  usePrompt('You have unsaved changes. Are you sure you want to leave?', isFormDirty);

  function handleBillingNpiUbKeyDown2(e: any) {
    if (e.key === 'Tab') {
      e.preventDefault();
      const nextInput = document.getElementById('basic_57a_billing_id');
      if (nextInput) {
        nextInput.focus();
      }
    }
  }

  return (
    <div className="hcfa-form">
      <FormErrorList
        fullWarningInfo={fullWarningInfo}
        tableName={UB04_FIELDS.servicesList}
        errorInfo={errorInfo}
        fullErrorInfo={fullErrorInfo}
      />
      <Form
        form={form}
        size="small"
        name="basic"
        layout="vertical"
        scrollToFirstError={false}
        autoComplete="off"
        disabled={disabled}
        initialValues={
          isNew
            ? {
                [UB04_FIELDS.servicesList]: [undefined],
                [UB04_FIELDS.billingName]: providerNameOidc,
                [UB04_FIELDS.billingAddress]: providerAddressOidc,
                [UB04_FIELDS.billingNpi]: npiOidc,
                [UB04_FIELDS.billing_addr2]: providerAddressOtherOidc,
                [UB04_FIELDS.codeQualifier]: '0',
                [UB04_FIELDS.billingCity]: providerCityOidc,
                [UB04_FIELDS.billingState]: providerStateOidc,
                [UB04_FIELDS.billingZip]: providerZipCodeOidc,
                [UB04_FIELDS.federalTaxNumber]: taxID
              }
            : undefined
        }
        onValuesChange={(changedValue, formValues) => {
          setData(formValues);
          handleFormChanges(changedValue);
        }}
      >
        {disabled && (
          <p
            style={{
              textAlign: 'center',
              opacity: '0.5'
            }}
          >
            <InfoCircleOutlined style={{ marginRight: '7px' }} />
            <i>Read only mode</i>
          </p>
        )}
        <div className="ub-form-wrapper">
          <div className="grid-container1">
            <div className="item1">
              <label className="form_label_color">1. {'Billing Name'}</label>
              <Form.Item
                label={t('ub04:form.billingName')}
                name={UB04_FIELDS.billingName}
                rules={[getYupValidator(t, UB04_FIELDS.billingName)]}
                required
              >
                <Input autoFocus onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
              <FieldsetLocation
                consecutiveAddress={true}
                form="ub04"
                ids={{
                  address: UB04_FIELDS.billingAddress,
                  city: UB04_FIELDS.billingCity,
                  state: UB04_FIELDS.billingState,
                  zipcode: UB04_FIELDS.billingZip,
                  addressTwo: UB04_FIELDS.billing_addr2,
                  phone: UB04_FIELDS.billingPhone
                }}
                labels={{
                  address: t('ub04:form.billingAddress'),
                  city: t('ub04:form.billingCity'),
                  state: t('ub04:form.billingState'),
                  zipcode: t('ub04:form.billingZip'),
                  addressTwo: t('ub04:form.addressTwo'),
                  phone: t('ub04:form.billingPhone')
                }}
                breakPoints={{
                  address: 24,
                  city: 24,
                  state: 12,
                  zipcode: 12,
                  addressTwo: 24,
                  phone: 24
                }}
              />
            </div>
            <div className="item2">
              <label className="form_label_color">2. {'Pay-to Name and Address'}</label>
              <Form.Item
                label={t('ub04:form.payToName')}
                name={UB04_FIELDS.payToName}
                rules={[getYupValidator(t, UB04_FIELDS.payToName)]}
              >
                <Input
                  placeholder="ABC Medical Center"
                  onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                />
              </Form.Item>
              <FieldsetLocation
                consecutiveAddress={true}
                form="ub04"
                ids={{
                  address: UB04_FIELDS.payToAddress,
                  city: UB04_FIELDS.payToCity,
                  state: UB04_FIELDS.payToState,
                  zipcode: UB04_FIELDS.payToZip,
                  addressTwo: UB04_FIELDS.pay_to_addr2,
                  phone: UB04_FIELDS.payToPhone
                }}
                labels={{
                  address: t('ub04:form.payToAddress'),
                  city: t('ub04:form.payToCity'),
                  state: t('ub04:form.payToState'),
                  zipcode: t('ub04:form.payToZip'),
                  addressTwo: t('ub04:form.addressTwo'),
                  phone: t('ub04:form.payToPhone')
                }}
                breakPoints={{
                  address: 24,
                  city: 24,
                  state: 12,
                  zipcode: 12,
                  addressTwo: 24,
                  phone: 24
                }}
              />
            </div>
            <div className="item3">
              <label className="form_label_color">3a. {'Patient control number'}</label>
              <Form.Item
                name={UB04_FIELDS.patientControlNumber}
                rules={[getYupValidator(t, UB04_FIELDS.patientControlNumber)]}
              >
                <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
            </div>
            <div className="item4">
              <label className="form_label_color">b. {'Medical record number'}</label>
              <Form.Item
                name={UB04_FIELDS.medicalRecordNumber}
                rules={[getYupValidator(t, UB04_FIELDS.medicalRecordNumber)]}
              >
                <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
            </div>
            <div className="item5">
              <label className="form_label_color">
                4. <span className="red">* </span>
                {'Type of Bill'}
              </label>
              <Form.Item name={UB04_FIELDS.typeOfBill} required rules={[getYupValidator(t, UB04_FIELDS.typeOfBill)]}>
                <Input placeholder="123" />
              </Form.Item>
              {/* {typeOfBill &&
                (typeOfBill.length === 3 || typeOfBill.length === 4) &&
                (typeOfBill.split('').pop() === '7' || typeOfBill.split('').pop() === '8') && (
                  <Alert
                    style={{ margin: '5px 5px 0px 5px' }}
                    message={CLAIM_TYPES?.[typeOfBill.split('').pop()]}
                    type="info"
                    showIcon
                  />
                )} */}
            </div>
            <div className="item6">
              <label className="form_label_color">5. {'Federal tax number'}</label>
              <Form.Item
                name={UB04_FIELDS.federalTaxNumber}
                rules={[getYupValidator(t, UB04_FIELDS.federalTaxNumber)]}
                required
              >
                <Input placeholder="123456789" />
              </Form.Item>
            </div>
            <div className="item7">
              <label className="form_label_color">6. {'Statement covers period'}</label>
              <Form.Item
                label={'From'}
                required
                name={UB04_FIELDS.coversPeriodFrom}
                rules={[
                  getYupValidator(t, UB04_FIELDS.coversPeriodFrom),
                  getYupWarning(t, UB04_FIELDS.coversPeriodFrom)
                ]}
              >
                <DatePickerAutoPopulate
                  suffixIcon={null}
                  style={{ width: '100%' }}
                  panelRender={() => false}
                  format={DATE_FORMAT}
                  placeholder={t('common:fields.dateFormat')}
                  name={UB04_FIELDS.coversPeriodFrom}
                  form={form!}
                />
              </Form.Item>
              <Form.Item
                label={'To'}
                required
                name={UB04_FIELDS.coversPeriodTo}
                rules={[getYupValidator(t, UB04_FIELDS.coversPeriodTo), getYupWarning(t, UB04_FIELDS.coversPeriodTo)]}
              >
                <DatePickerAutoPopulate
                  suffixIcon={null}
                  style={{ width: '100%' }}
                  panelRender={() => false}
                  format={DATE_FORMAT}
                  placeholder={t('common:fields.dateFormat')}
                  name={UB04_FIELDS.coversPeriodTo}
                  form={form!}
                />
              </Form.Item>
            </div>
            <div className="item8">
              <label className="form_label_color">7. {'Administratively Necessary Days'}</label>
              <Form.Item
                name={UB04_FIELDS.adminNecessaryDays}
                required
                rules={[getYupValidator(t, UB04_FIELDS.adminNecessaryDays)]}
              >
                <Input onInput={toPositiveNumber} placeholder="12345" />
              </Form.Item>
            </div>
          </div>
          <div className="grid-container2">
            <div className="item9">
              <label className="form_label_color">8. {'Patient name'}</label>
              <Form.Item
                label={t('common:fields.patient.id')}
                name={UB04_FIELDS.patientId}
                rules={[getYupValidator(t, UB04_FIELDS.patientId)]}
              >
                <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
              <FieldsetFullName
                form="ub04"
                lastNameId={UB04_FIELDS.patientLastName}
                firstNameId={UB04_FIELDS.patientFirstName}
                middleInitialId={UB04_FIELDS.patientMiddleInitial}
                generationId={UB04_FIELDS.patientGen}
                labels={{
                  lastName: t('common:fields.lastName'),
                  firstName: t('common:fields.firstName'),
                  middleInitial: t('common:fields.middleInitial'),
                  generation: t('common:fields.generation')
                }}
                breakPoints={{
                  lastName: 7,
                  firstName: 7,
                  middleInitial: 6,
                  generation: 4
                }}
                isRequired={{
                  lastName: true,
                  firstName: true
                }}
              />
            </div>
            <div className="item10">
              <label className="form_label_color">9. {'Patient address'}</label>
              <FieldsetLocation
                form="ub04"
                ids={{
                  address: UB04_FIELDS.patientAddress,
                  city: UB04_FIELDS.patientCity,
                  state: UB04_FIELDS.patientState,
                  zipcode: UB04_FIELDS.patientZip,
                  addressTwo: UB04_FIELDS.pat_addr2
                }}
                labels={{
                  address: t('common:fields.address'),
                  city: t('common:fields.city'),
                  state: t('common:fields.state'),
                  zipcode: t('common:fields.zipCode'),
                  addressTwo: t('ub04:form.addressTwo')
                }}
                breakPoints={{
                  address: 12,
                  city: 4,
                  state: 4,
                  zipcode: 4
                }}
              />
            </div>
          </div>

          <div className="grid-container3">
            <div className="grid-container3-1">
              <div className="item11">
                <label className="form_label_color form_label_botton">10. {t('common:fields.birthDate')}</label>
                <Form.Item
                  name={UB04_FIELDS.patientBirthdate}
                  rules={[
                    getYupValidator(t, UB04_FIELDS.patientBirthdate),
                    getYupWarning(t, UB04_FIELDS.patientBirthdate)
                  ]}
                >
                  <DatePickerAutoPopulate
                    suffixIcon={null}
                    className="w-100"
                    placeholder={t('common:fields.dateFormat')}
                    panelRender={() => false}
                    format={DATE_FORMAT}
                    name={UB04_FIELDS.patientBirthdate}
                    form={form!}
                  />
                </Form.Item>
              </div>
              <div className="item12">
                <label className="form_label_color">
                  11. <span className="red">* </span> {t('common:fields.sex')}
                </label>
                <FormItemSex id={UB04_FIELDS.patientSex} hideLabel={true} />
              </div>
              <div className="item13">
                <label className="form_label_color">Admission</label>
                <div className="cc-flex-warp">
                  <div>
                    <Form.Item
                      label="12. date"
                      name={UB04_FIELDS.admissionDate}
                      rules={[
                        getYupValidator(t, UB04_FIELDS.admissionDate),
                        getYupWarning(t, UB04_FIELDS.admissionDate)
                      ]}
                    >
                      <DatePickerAutoPopulate
                        suffixIcon={null}
                        panelRender={() => false}
                        format={DATE_FORMAT}
                        placeholder={t('common:fields.dateFormat')}
                        name={UB04_FIELDS.admissionDate}
                        form={form!}
                      />
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      label="13. hour"
                      name={UB04_FIELDS.admissionHour}
                      rules={[getYupValidator(t, UB04_FIELDS.admissionHour)]}
                    >
                      <Select
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())
                        }
                        style={{ width: '60px' }}
                        onSearch={(input) => {
                          onSearchSelect(form, UB04_FIELDS.admissionHour, input, HOURS);
                        }}
                        dropdownStyle={{
                          minWidth: '45px'
                        }}
                      >
                        {HOURS.map((code) => (
                          <Select.Option key={code} value={code}>
                            {code}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      label="14. type"
                      name={UB04_FIELDS.admissionType}
                      rules={[getYupValidator(t, UB04_FIELDS.admissionType)]}
                    >
                      <Select
                        showSearch
                        allowClear
                        filterOption={(input, option) =>
                          (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())
                        }
                        style={{ width: '60px' }}
                        onSearch={(input) => {
                          onSearchSelect(form, UB04_FIELDS.admissionType, input, ADMISSION_TYPE);
                        }}
                      >
                        {ADMISSION_TYPE.map((code) => (
                          <Select.Option key={code} value={code}>
                            {code}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                  <div>
                    <Form.Item
                      label="15. source"
                      name={UB04_FIELDS.admissionSource}
                      rules={[getYupValidator(t, UB04_FIELDS.admissionSource, form?.getFieldsValue())]}
                    >
                      <Select
                        allowClear
                        showSearch
                        filterOption={(input, option) =>
                          (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())
                        }
                        style={{ width: '60px' }}
                        onSearch={(input) => {
                          onSearchSelect(form, UB04_FIELDS.admissionSource, input, ADMISSION_SOURCE);
                        }}
                      >
                        {ADMISSION_SOURCE.map((code) => (
                          <Select.Option key={code} value={code}>
                            {code}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="item14">
                <label className="form_label_color form_label_botton">16. {'DHR'}</label>
                <Form.Item name={UB04_FIELDS.dischargeHour} rules={[getYupValidator(t, UB04_FIELDS.dischargeHour)]}>
                  <Select
                    showSearch
                    allowClear
                    filterOption={(input, option) =>
                      (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())
                    }
                    onSearch={(input) => {
                      onSearchSelect(form, UB04_FIELDS.dischargeHour, input, HOURS);
                    }}
                  >
                    {HOURS.map((code) => (
                      <Select.Option key={code} value={code}>
                        {code}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
            </div>
            <div className="grid-container3-2">
              <div className="item15">
                <label className="form_label_color form_label_botton">
                  17. <span className="red">* </span> {'STAT'}
                </label>
                <Form.Item
                  name={UB04_FIELDS.dischargeStatus}
                  required
                  rules={[getYupValidator(t, UB04_FIELDS.dischargeStatus)]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())
                    }
                    onSearch={(input) => {
                      onSearchSelect(
                        form,
                        UB04_FIELDS.dischargeStatus,
                        input,
                        Array.from(Array(100), (el, i) => i.toLocaleString().padStart(2, '0'))
                      );
                    }}
                  >
                    {Array.from({ length: 100 }, (_, index) => (
                      <Select.Option key={index} value={index.toString().padStart(2, '0')}>
                        {index.toString().padStart(2, '0')}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="item16">
                <label className="form_label_color">{'Condition codes'}</label>
                <div className="cc_wrapper cc-flex">
                  <div className="cc_fields_wrapper">
                    <Form.Item
                      label="18"
                      name={UB04_FIELDS.conditionCode18}
                      rules={[getYupValidator(t, UB04_FIELDS.conditionCode18)]}
                    >
                      <Input onInput={toAlphaNumUppercase} />
                    </Form.Item>
                  </div>
                  <div className="cc_fields_wrapper">
                    <Form.Item
                      label="19"
                      name={UB04_FIELDS.conditionCode19}
                      rules={[getYupValidator(t, UB04_FIELDS.conditionCode19)]}
                    >
                      <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                    </Form.Item>
                  </div>
                  <div className="cc_fields_wrapper">
                    <Form.Item
                      label="20"
                      name={UB04_FIELDS.conditionCode20}
                      rules={[getYupValidator(t, UB04_FIELDS.conditionCode20)]}
                    >
                      <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                    </Form.Item>
                  </div>
                  <div className="cc_fields_wrapper">
                    <Form.Item
                      label="21"
                      name={UB04_FIELDS.conditionCode21}
                      rules={[getYupValidator(t, UB04_FIELDS.conditionCode21)]}
                    >
                      <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                    </Form.Item>
                  </div>
                  <div className="cc_fields_wrapper">
                    <Form.Item
                      label="22"
                      name={UB04_FIELDS.conditionCode22}
                      rules={[getYupValidator(t, UB04_FIELDS.conditionCode22)]}
                    >
                      <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                    </Form.Item>
                  </div>
                  <div className="cc_fields_wrapper">
                    <Form.Item
                      label="23"
                      name={UB04_FIELDS.conditionCode23}
                      rules={[getYupValidator(t, UB04_FIELDS.conditionCode23)]}
                    >
                      <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                    </Form.Item>
                  </div>
                  <div className="cc_fields_wrapper">
                    <Form.Item
                      label="24"
                      name={UB04_FIELDS.conditionCode24}
                      rules={[getYupValidator(t, UB04_FIELDS.conditionCode24)]}
                    >
                      <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                    </Form.Item>
                  </div>
                  <div className="cc_fields_wrapper">
                    <Form.Item
                      label="25"
                      name={UB04_FIELDS.conditionCode25}
                      rules={[getYupValidator(t, UB04_FIELDS.conditionCode25)]}
                    >
                      <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                    </Form.Item>
                  </div>
                  <div className="cc_fields_wrapper">
                    <Form.Item
                      label="26"
                      name={UB04_FIELDS.conditionCode26}
                      rules={[getYupValidator(t, UB04_FIELDS.conditionCode26)]}
                    >
                      <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                    </Form.Item>
                  </div>
                  <div className="cc_fields_wrapper">
                    <Form.Item
                      label="27"
                      name={UB04_FIELDS.conditionCode27}
                      rules={[getYupValidator(t, UB04_FIELDS.conditionCode27)]}
                    >
                      <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                    </Form.Item>
                  </div>
                  <div className="cc_fields_wrapper">
                    <Form.Item
                      label="28"
                      name={UB04_FIELDS.conditionCode28}
                      rules={[getYupValidator(t, UB04_FIELDS.conditionCode28)]}
                    >
                      <Input onInput={toAlphaNumUppercase} />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="item17">
                <label className="form_label_color form_label_botton">29. {'ACDT STATE'}</label>
                <Form.Item name={UB04_FIELDS.accidentState}>
                  <SelectState placeholder="STATE" inputKey={UB04_FIELDS.accidentState} />
                </Form.Item>
              </div>
            </div>
          </div>
          <section className="padding-0 border-0 grid-container4-wrapper">
            <div className="grid-container4-1">
              <div className="item19">
                <label className="form_label_color">31. {'Occurrence'}</label>
                <FieldsetOccurrence
                  labels={{ code: '31-a code', date: '31-a date' }}
                  codeId={UB04_FIELDS.occurrenceCode31A}
                  dateId={UB04_FIELDS.occurrenceDate31A}
                />
                <FieldsetOccurrence
                  labels={{ code: '31-b code', date: '31-b date' }}
                  codeId={UB04_FIELDS.occurrenceCode31B}
                  dateId={UB04_FIELDS.occurrenceDate31B}
                />
              </div>
              <div className="item20">
                <label className="form_label_color">32. {'Occurrence'}</label>
                <FieldsetOccurrence
                  labels={{ code: '32-a code', date: '32-a date' }}
                  codeId={UB04_FIELDS.occurrenceCode32A}
                  dateId={UB04_FIELDS.occurrenceDate32A}
                />
                <FieldsetOccurrence
                  labels={{ code: '32-b code', date: '32-b date' }}
                  codeId={UB04_FIELDS.occurrenceCode32B}
                  dateId={UB04_FIELDS.occurrenceDate32B}
                />
              </div>
              <div className="item21">
                <label className="form_label_color">33. {'Occurrence'}</label>
                <FieldsetOccurrence
                  labels={{ code: '33-a code', date: '33-a date' }}
                  codeId={UB04_FIELDS.occurrenceCode33A}
                  dateId={UB04_FIELDS.occurrenceDate33A}
                />
                <FieldsetOccurrence
                  labels={{ code: '33-b code', date: '33-b date' }}
                  codeId={UB04_FIELDS.occurrenceCode33B}
                  dateId={UB04_FIELDS.occurrenceDate33B}
                />
              </div>
              <div className="item22">
                <label className="form_label_color">34. {'Occurrence'}</label>
                <FieldsetOccurrence
                  labels={{ code: '34-a code', date: '34-a date' }}
                  codeId={UB04_FIELDS.occurrenceCode34A}
                  dateId={UB04_FIELDS.occurrenceDate34A}
                />
                <FieldsetOccurrence
                  labels={{ code: '34-b code', date: '34-b date' }}
                  codeId={UB04_FIELDS.occurrenceCode34B}
                  dateId={UB04_FIELDS.occurrenceDate34B}
                />
              </div>
            </div>
            <div className="grid-container5-1">
              <div className="item23">
                <label className="form_label_color">35. {'Occurrence span'}</label>
                <FieldsetSpanOccurrence
                  labels={{ code: '35-a code', dateFrom: '35-a date from', dateTo: '35-a date to' }}
                  codeId={UB04_FIELDS.occurrenceSpan35ACode}
                  dateFromId={UB04_FIELDS.occurrenceSpan35AFrom}
                  dateToId={UB04_FIELDS.occurrenceSpan35ATo}
                />
                <FieldsetSpanOccurrence
                  labels={{ code: '35-b code', dateFrom: '35-b date from', dateTo: '35-b date to' }}
                  codeId={UB04_FIELDS.occurrenceSpan35BCode}
                  dateFromId={UB04_FIELDS.occurrenceSpan35BFrom}
                  dateToId={UB04_FIELDS.occurrenceSpan35BTo}
                />
              </div>
              <div className="item24">
                <label className="form_label_color">36. {'Occurrence span'}</label>
                <FieldsetSpanOccurrence
                  labels={{ code: '36-a code', dateFrom: '36-a date from', dateTo: '36-a date to' }}
                  codeId={UB04_FIELDS.occurrenceSpan36ACode}
                  dateFromId={UB04_FIELDS.occurrenceSpan36AFrom}
                  dateToId={UB04_FIELDS.occurrenceSpan36ATo}
                />
                <FieldsetSpanOccurrence
                  labels={{ code: '36-b code', dateFrom: '36-b date from', dateTo: '36-b date to' }}
                  codeId={UB04_FIELDS.occurrenceSpan36BCode}
                  dateFromId={UB04_FIELDS.occurrenceSpan36BFrom}
                  dateToId={UB04_FIELDS.occurrenceSpan36BTo}
                />
              </div>
              <div className="item25">
                <label className="form_label_color">37.</label>
                <Form.Item
                  className="field--first"
                  label="A"
                  name={UB04_FIELDS.ub37_a}
                  rules={[getYupValidator(t, UB04_FIELDS.ub37_a)]}
                >
                  <Input placeholder="ABC" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                </Form.Item>
                <Form.Item
                  className="field--first"
                  label="B"
                  name={UB04_FIELDS.ub37_b}
                  rules={[getYupValidator(t, UB04_FIELDS.ub37_b)]}
                >
                  <Input placeholder="ABC" onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                </Form.Item>
              </div>
            </div>
          </section>
          <div className="grid-container6">
            <div className="item26">
              <label className="form_label_color">38. {'RESPONSIBLE PARTY DETAILS'}</label>
              <FieldsetFullName
                form="ub04"
                lastNameId={UB04_FIELDS.responsiblePartyLastName}
                firstNameId={UB04_FIELDS.responsiblePartyFirstName}
                middleInitialId={UB04_FIELDS.responsiblePartyMiddleInitial}
                generationId={UB04_FIELDS.responsiblePartyGen}
                breakPoints={{
                  lastName: 6,
                  firstName: 6,
                  middleInitial: 6,
                  generation: 6
                }}
              />

              <FieldsetLocation
                form="ub04"
                ids={{
                  address: UB04_FIELDS.responsiblePartyAddress,
                  addressTwo: UB04_FIELDS.responsiblePartyAddressTwo,
                  city: UB04_FIELDS.responsiblePartyCity,
                  state: UB04_FIELDS.responsiblePartyState,
                  zipcode: UB04_FIELDS.responsiblePartyZip
                }}
                breakPoints={{
                  address: 12,
                  addressTwo: 12,
                  city: 8,
                  state: 8,
                  zipcode: 8
                }}
              />
            </div>
            <div className="item27">
              <label className="form_label_color">39. {'Value Codes'}</label>
              <FieldsetValueCode
                labels={{ code: '39-a code', amount: '39-a amount' }}
                codeId={UB04_FIELDS.value39ACode}
                amountId={UB04_FIELDS.value39AAmount}
              />
              <FieldsetValueCode
                labels={{ code: '39-b code', amount: '39-b amount' }}
                codeId={UB04_FIELDS.value39BCode}
                amountId={UB04_FIELDS.value39BAmount}
              />
              <FieldsetValueCode
                labels={{ code: '39-c code', amount: '39-c amount' }}
                codeId={UB04_FIELDS.value39CCode}
                amountId={UB04_FIELDS.value39CAmount}
              />
              <FieldsetValueCode
                labels={{ code: '39-d code', amount: '39-d amount' }}
                codeId={UB04_FIELDS.value39DCode}
                amountId={UB04_FIELDS.value39DAmount}
              />
            </div>
            <div className="item28">
              <label className="form_label_color">40. {'Value Codes'}</label>
              <FieldsetValueCode
                labels={{ code: '40-a code', amount: '40-a amount' }}
                codeId={UB04_FIELDS.value40ACode}
                amountId={UB04_FIELDS.value40AAmount}
              />
              <FieldsetValueCode
                labels={{ code: '40-b code', amount: '40-b amount' }}
                codeId={UB04_FIELDS.value40BCode}
                amountId={UB04_FIELDS.value40BAmount}
              />
              <FieldsetValueCode
                labels={{ code: '40-c code', amount: '40-c amount' }}
                codeId={UB04_FIELDS.value40CCode}
                amountId={UB04_FIELDS.value40CAmount}
              />
              <FieldsetValueCode
                labels={{ code: '40-d code', amount: '40-d amount' }}
                codeId={UB04_FIELDS.value40DCode}
                amountId={UB04_FIELDS.value40DAmount}
              />
            </div>
            <div className="item29">
              <label className="form_label_color">41. {'Value Codes'}</label>
              <FieldsetValueCode
                labels={{ code: '41-a code', amount: '41-a amount' }}
                codeId={UB04_FIELDS.value41ACode}
                amountId={UB04_FIELDS.value41AAmount}
              />
              <FieldsetValueCode
                labels={{ code: '41-b code', amount: '41-b amount' }}
                codeId={UB04_FIELDS.value41BCode}
                amountId={UB04_FIELDS.value41BAmount}
              />
              <FieldsetValueCode
                labels={{ code: '41-c code', amount: '41-c amount' }}
                codeId={UB04_FIELDS.value41CCode}
                amountId={UB04_FIELDS.value41CAmount}
              />
              <FieldsetValueCode
                labels={{ code: '41-d code', amount: '41-d amount' }}
                codeId={UB04_FIELDS.value41DCode}
                amountId={UB04_FIELDS.value41DAmount}
              />
            </div>
          </div>
          <div className="black-border">
            <ServicesFieldsList readOnly={disabled} />
          </div>

          <div className="grid-container7_addition add-1">
            {/* <div className="item31_update_a">
              <label className="form_label_color">{'Destination Payer'}</label>
            </div> */}
            <div className="grid-container7 item31_update_b no-border no-padding">
              <div className="item30">
                <label className="form_label_color">50. {'PAYER NAME'}</label>
              </div>
              <div className="item31">
                <label className="form_label_color">51. {'HEALTH PLAN ID'}</label>
              </div>
              <div className="item32">
                <label className="form_label_color">52. {'REL. INFO'}</label>
              </div>
              <div className="item33">
                <label className="form_label_color">53. {'ASG. BEN.'}</label>
              </div>
              <div className="item34">
                <label className="form_label_color">54. {'PRIOR PAYMENTS'}</label>
              </div>
              <div className="item35">
                <label className="form_label_color">55. {'EST. AMOUNT DUE'}</label>
              </div>
              <div className="item36 no-br">
                <label className="form_label_color">56. {'NPI'}</label>
              </div>
              <div className="item37 no-bl">
                <Form.Item name={UB04_FIELDS.billingNpi} rules={[getYupValidator(t, UB04_FIELDS.billingNpi)]}>
                  <Input onKeyDown={(e) => handleBillingNpiUbKeyDown2(e)} />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className="grid-container7_addition add-2">
            <div className="item31_update_b no-border no-padding" style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="flex-1">
                <FieldsetPayerInformation
                  nameId={UB04_FIELDS.payer50A}
                  healthPlanId={UB04_FIELDS.healthPlan51A}
                  releaseInfoId={UB04_FIELDS.releaseInfo52A}
                  benefitsId={UB04_FIELDS.assignmentOfBenefits53A}
                  priorPaymentsId={UB04_FIELDS.priorPayments54A}
                  estAmountId={UB04_FIELDS.estimatedAmount55A}
                  //billingNpiId={UB04_FIELDS.billingNpi}
                  otherName="57. OTHER PRV ID A"
                  otherId={UB04_FIELDS.otherBillingId57A}
                />
              </div>
              <div className="flex-1">
                <FieldsetPayerInformation
                  nameId={UB04_FIELDS.payer50B}
                  healthPlanId={UB04_FIELDS.healthPlan51B}
                  releaseInfoId={UB04_FIELDS.releaseInfo52B}
                  benefitsId={UB04_FIELDS.assignmentOfBenefits53B}
                  priorPaymentsId={UB04_FIELDS.priorPayments54B}
                  estAmountId={UB04_FIELDS.estimatedAmount55B}
                  otherName="B."
                  otherId={UB04_FIELDS.otherBillingId57B}
                />
              </div>
              <div className="flex-1">
                <FieldsetPayerInformation
                  nameId={UB04_FIELDS.payer50C}
                  healthPlanId={UB04_FIELDS.healthPlan51C}
                  releaseInfoId={UB04_FIELDS.releaseInfo52C}
                  benefitsId={UB04_FIELDS.assignmentOfBenefits53C}
                  priorPaymentsId={UB04_FIELDS.priorPayments54C}
                  estAmountId={UB04_FIELDS.estimatedAmount55C}
                  otherName="C."
                  otherId={UB04_FIELDS.otherBillingId57C}
                />
              </div>
            </div>
          </div>

          <div className="parent-flex insured-name-style">
            <section>
              <label className="form_label_color">58. {"INSURED'S NAME"}</label>
            </section>
            <section>
              <label className="form_label_color">59. {'P.REL'}</label>
            </section>
            <section>
              <label className="form_label_color">60. {"INSURED'S UNIQUE ID"}</label>
            </section>
            <section>
              <label className="form_label_color">61. {'GROUP NAME'}</label>
            </section>
            <section>
              <label className="form_label_color">62. {'INSURANCE GROUP NO.'}</label>
            </section>
          </div>
          <div className="fields-58-62">
            {INSURED.map((insured, index) => (
              <div className="parent-flex insured-name-style" key={index}>
                <section>
                  <FieldsetFullName
                    form="ub04"
                    lastNameId={insured.lastNameId}
                    firstNameId={insured.firstNameId}
                    middleInitialId={insured.middleInitialId}
                    generationId={insured.generationId}
                    breakPoints={{
                      lastName: 8,
                      firstName: 8,
                      middleInitial: 4,
                      generation: 4
                    }}
                    isRequired={{
                      lastName: insured.title === 'INSURED A',
                      firstName: insured.title === 'INSURED A'
                    }}
                    labels={{
                      generation: 'GEN.'
                    }}
                    //direction="vertical"
                  />
                </section>
                <FieldsetInsuredInformation
                  patientsRelationshipId={insured.patientsRelationshipId}
                  uniqueIdentifierId={insured.uniqueIdentifierId}
                  groupNameId={insured.groupNameId}
                  groupNumberId={insured.groupNumberId}
                />
              </div>
            ))}
          </div>
          <div className="grid-container7_a">
            <div className="item37_a">
              <label className="form_label_color">63. {'TREATMENT AUTHORIZATION CODES'}</label>
            </div>
            <div className="item37_b">
              <label className="form_label_color">64. {'DOCUMENT CONTROL NUMBER'}</label>
            </div>
            <div className="item37_c">
              <label className="form_label_color">65. {'EMPLOYER NAME'}</label>
            </div>
          </div>
          <div className="">
            <FieldsetTreatmentInformation
              treatmentCodeId={UB04_FIELDS.authorizationCode63a}
              documentNumberId={UB04_FIELDS.documentControlNumber64a}
              employerNameId={UB04_FIELDS.employerName65a}
              docControlNumLeng={{
                minLen: 12,
                maxLen: 12
              }}
              showAdditionalInfo
            />
            <FieldsetTreatmentInformation
              treatmentCodeId={UB04_FIELDS.authorizationCode63b}
              documentNumberId={UB04_FIELDS.documentControlNumber64b}
              employerNameId={UB04_FIELDS.employerName65b}
              docControlNumLeng={{
                minLen: 1,
                maxLen: 26
              }}
            />
            <FieldsetTreatmentInformation
              treatmentCodeId={UB04_FIELDS.authorizationCode63c}
              documentNumberId={UB04_FIELDS.documentControlNumber64c}
              employerNameId={UB04_FIELDS.employerName65c}
              docControlNumLeng={{
                minLen: 1,
                maxLen: 26
              }}
            />
          </div>
          <div className="grid-container8">
            <div className="item38">
              <label className="form_label_color">66. {'DX'}</label>
              <Form.Item
                name={UB04_FIELDS.codeQualifier}
                label="Code qualifier"
                rules={[getYupValidator(t, UB04_FIELDS.codeQualifier)]}
              >
                <Select
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (`${option?.value}` ?? '').toLowerCase().includes(input?.toLowerCase())
                  }
                  defaultValue={'0'}
                >
                  <Select.Option value="0">0</Select.Option>
                  <Select.Option value="9">9</Select.Option>
                </Select>
              </Form.Item>
            </div>
            <div className="item39">
              <label className="form_label_color">67.</label>
              <FieldsetDiagnosisCode
                codeNameId={UB04_FIELDS.principalDiagnoseDx}
                codeSelectId={UB04_FIELDS.principalDiagnosePoa}
                labels={{
                  codeName: 'Principal code',
                  codeSelect: 'POA.'
                }}
                isPaperForm={true}
              />
            </div>
            <div className="item40">
              <label className="form_label_color" style={{ opacity: '0' }}>
                67.A
              </label>
              {renderAdditionalCodes(0)}
            </div>
            <div className="item41">
              <label className="form_label_color" style={{ opacity: '0' }}>
                67.B
              </label>
              {renderAdditionalCodes(1)}
            </div>
            <div className="item42">
              <label className="form_label_color" style={{ opacity: '0' }}>
                67.C
              </label>
              {renderAdditionalCodes(2)}
            </div>
            <div className="item43">
              <label className="form_label_color" style={{ opacity: '0' }}>
                67.D
              </label>
              {renderAdditionalCodes(3)}
            </div>
            <div className="item44">
              <label className="form_label_color" style={{ opacity: '0' }}>
                67.E
              </label>
              {renderAdditionalCodes(4)}
            </div>
            <div className="item45">
              <label className="form_label_color" style={{ opacity: '0' }}>
                67.F
              </label>
              {renderAdditionalCodes(5)}
            </div>
            <div className="item46">
              <label className="form_label_color" style={{ opacity: '0' }}>
                67.G
              </label>
              {renderAdditionalCodes(6)}
            </div>
            <div className="item47">
              <label className="form_label_color" style={{ opacity: '0' }}>
                67.H
              </label>
              {renderAdditionalCodes(7)}
            </div>
            <div className="item48">
              <label className="form_label_color">68.</label>
              <Form.Item label="A" name={UB04_FIELDS.ub68A} rules={[getYupValidator(t, UB04_FIELDS.ub68A)]}>
                <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
              <Form.Item label="B" name={UB04_FIELDS.ub68B} rules={[getYupValidator(t, UB04_FIELDS.ub68B)]}>
                <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
            </div>
          </div>
          <div className="grid-container9">
            <div className="item50">{renderAdditionalCodes(8)}</div>
            <div className="item51">{renderAdditionalCodes(9)}</div>
            <div className="item52">{renderAdditionalCodes(10)}</div>
            <div className="item53">{renderAdditionalCodes(11)}</div>
            <div className="item54">{renderAdditionalCodes(12)}</div>
            <div className="item55">{renderAdditionalCodes(13)}</div>
            <div className="item56">{renderAdditionalCodes(14)}</div>
            <div className="item57">{renderAdditionalCodes(15)}</div>
            <div className="item58">{renderAdditionalCodes(16)}</div>
          </div>
          <div className="grid-container10">
            <div className="item60">
              <label className="form_label_color">69. {'ADMIT DX'}</label>
              <Form.Item
                label="Adm. diagnosis code"
                name={UB04_FIELDS.admittingDiagnosisCode}
                rules={[getYupValidator(t, UB04_FIELDS.admittingDiagnosisCode)]}
              >
                <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
            </div>
            <div className="item61 no-padding">
              <label className="form_label_color bb">70. {'PATIENT REASON DX'}</label>
              <div className="grid-container11 full_height">
                <div className="item64_a no-bl">{renderReasonForVisit('a')}</div>
                <div className="item64_b">{renderReasonForVisit('b')}</div>
                <div className="item64_c no-br">{renderReasonForVisit('c')}</div>
              </div>
            </div>
            <div className="item62" style={{ padding: 0 }}>
              <label className="form_label_color" style={{ borderBottom: '2px solid black' }}>
                71. {'PPS CODE'}
              </label>
              <Form.Item
                label={<span>&nbsp;</span>}
                name={UB04_FIELDS.pps}
                rules={[getYupValidator(t, UB04_FIELDS.pps)]}
              >
                <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
            </div>
            <div className="item63 no-padding">
              <label className="form_label_color bb">72. {'ECI'}</label>
              <div className="grid-container11 full_height">
                <div className="item64_a no-bl">
                  <Col>
                    <FieldsetECICode
                      codeNameId={UB04_FIELDS.codeEci72a}
                      codeSelectId={UB04_FIELDS.codeEci72aPoa}
                      labels={{ codeName: 'ECI-a', codeSelect: 'a POA' }}
                      isPaperForm={true}
                    />
                  </Col>
                </div>
                <div className="item64_b">
                  <Col>
                    <FieldsetECICode
                      codeNameId={UB04_FIELDS.codeEci72b}
                      codeSelectId={UB04_FIELDS.codeEci72bPoa}
                      labels={{ codeName: 'ECI-b', codeSelect: 'b POA' }}
                      isPaperForm={true}
                    />
                  </Col>
                </div>
                <div className="item64_c no-br">
                  <Col>
                    <FieldsetECICode
                      codeNameId={UB04_FIELDS.codeEci72c}
                      codeSelectId={UB04_FIELDS.codeEci72cPoa}
                      labels={{ codeName: 'ECI-c', codeSelect: 'c POA' }}
                      isPaperForm={true}
                    />
                  </Col>
                </div>
              </div>
            </div>
            <div className="item64" style={{ padding: 0 }}>
              <label className="form_label_color" style={{ borderBottom: '2px solid black' }}>
                73. LOC
              </label>
              <Form.Item
                label={<span>&nbsp;</span>}
                name={UB04_FIELDS.ub73_loc}
                rules={[getYupValidator(t, UB04_FIELDS.ub73_loc)]}
              >
                <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
              </Form.Item>
            </div>
          </div>
          <div className="grid-container12a no-padding no-bt no-bl">
            <div className="item121 no-border">
              <div className="grid-container13">
                <div className="item_68">
                  <label className="form_label_color">74. PRINCIPAL PROCEDURE</label>
                </div>
                <div className="item_69">
                  <label className="form_label_color">a. OTHER PROCEDURE</label>
                </div>
                <div className="item_70">
                  <label className="form_label_color">b. OTHER PROCEDURE</label>
                </div>
                <div className="item_71">
                  <div className="ant-form-item-label">
                    <label>CODE</label>
                  </div>
                  <Form.Item
                    className="field--first"
                    name={UB04_FIELDS.principalProcedureCode}
                    rules={[getYupValidator(t, UB04_FIELDS.principalProcedureCode)]}
                  >
                    <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                  </Form.Item>
                </div>
                <div className="item_72">
                  <div className="ant-form-item-label">
                    <label>DATE</label>
                  </div>
                  <Form.Item
                    name={UB04_FIELDS.principalProcedureDate}
                    rules={[
                      getYupValidator(t, UB04_FIELDS.principalProcedureDate),
                      getYupWarning(t, UB04_FIELDS.principalProcedureDate)
                    ]}
                  >
                    <DatePickerAutoPopulate
                      suffixIcon={null}
                      style={{ width: '100%' }}
                      panelRender={() => false}
                      format={DATE_FORMAT}
                      placeholder={t('common:fields.dateFormat')}
                      name={UB04_FIELDS.principalProcedureDate}
                      form={form!}
                    />
                  </Form.Item>
                </div>
                <div className="item_73">
                  <div className="ant-form-item-label">
                    <label>CODE</label>
                  </div>
                  <Form.Item
                    className="field--first"
                    name={UB04_FIELDS.procedureCode74a}
                    rules={[getYupValidator(t, UB04_FIELDS.procedureCode74a)]}
                  >
                    <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                  </Form.Item>
                </div>
                <div className="item_74">
                  <div className="ant-form-item-label">
                    <label>DATE</label>
                  </div>
                  <Form.Item
                    name={UB04_FIELDS.procedureDate74a}
                    rules={[
                      getYupValidator(t, UB04_FIELDS.procedureDate74a),
                      getYupWarning(t, UB04_FIELDS.procedureDate74a)
                    ]}
                  >
                    <DatePickerAutoPopulate
                      suffixIcon={null}
                      style={{ width: '100%' }}
                      panelRender={() => false}
                      format={DATE_FORMAT}
                      placeholder={t('common:fields.dateFormat')}
                      name={UB04_FIELDS.procedureDate74a}
                      form={form!}
                    />
                  </Form.Item>
                </div>
                <div className="item_75">
                  <div className="ant-form-item-label">
                    <label>CODE</label>
                  </div>
                  <Form.Item
                    className="field--first"
                    name={UB04_FIELDS.procedureCode74b}
                    rules={[getYupValidator(t, UB04_FIELDS.procedureCode74b)]}
                  >
                    <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                  </Form.Item>
                </div>
                <div className="item_76">
                  <div className="ant-form-item-label">
                    <label>DATE</label>
                  </div>
                  <Form.Item
                    name={UB04_FIELDS.procedureDate74b}
                    rules={[
                      getYupValidator(t, UB04_FIELDS.procedureDate74b),
                      getYupWarning(t, UB04_FIELDS.procedureDate74b)
                    ]}
                  >
                    <DatePickerAutoPopulate
                      suffixIcon={null}
                      style={{ width: '100%' }}
                      panelRender={() => false}
                      format={DATE_FORMAT}
                      placeholder={t('common:fields.dateFormat')}
                      name={UB04_FIELDS.procedureDate74b}
                      form={form!}
                    />
                  </Form.Item>
                </div>
                <div className="item_77">
                  <label className="form_label_color">c. OTHER PROCEDURE</label>
                </div>
                <div className="item_78">
                  <label className="form_label_color">d. OTHER PROCEDURE</label>
                </div>
                <div className="item_79">
                  <label className="form_label_color">e. OTHER PROCEDURE</label>
                </div>
                <div className="item_80" style={{ padding: 0 }}>
                  <label className="form_label_color" style={{ borderBottom: '2px solid black' }}>
                    75.
                  </label>
                  <Form.Item
                    //label={<span>&nbsp;</span>}
                    className="field--first"
                    name={UB04_FIELDS.ub75}
                    rules={[getYupValidator(t, UB04_FIELDS.ub75)]}
                  >
                    <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                  </Form.Item>
                </div>
                <div className="item_81">
                  <div className="ant-form-item-label">
                    <label>CODE</label>
                  </div>
                  <Form.Item
                    className="field--first"
                    name={UB04_FIELDS.procedureCode74c}
                    rules={[getYupValidator(t, UB04_FIELDS.procedureCode74c)]}
                  >
                    <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                  </Form.Item>
                </div>
                <div className="item_82">
                  <div className="ant-form-item-label">
                    <label>DATE</label>
                  </div>
                  <Form.Item
                    name={UB04_FIELDS.procedureDate74c}
                    rules={[
                      getYupValidator(t, UB04_FIELDS.procedureDate74c),
                      getYupWarning(t, UB04_FIELDS.procedureDate74c)
                    ]}
                  >
                    <DatePickerAutoPopulate
                      suffixIcon={null}
                      style={{ width: '100%' }}
                      panelRender={() => false}
                      format={DATE_FORMAT}
                      placeholder={t('common:fields.dateFormat')}
                      name={UB04_FIELDS.procedureDate74c}
                      form={form!}
                    />
                  </Form.Item>
                </div>
                <div className="item_83">
                  <div className="ant-form-item-label">
                    <label>CODE</label>
                  </div>
                  <Form.Item
                    className="field--first"
                    name={UB04_FIELDS.procedureCode74d}
                    rules={[getYupValidator(t, UB04_FIELDS.procedureCode74d)]}
                  >
                    <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                  </Form.Item>
                </div>
                <div className="item_84">
                  <div className="ant-form-item-label">
                    <label>DATE</label>
                  </div>
                  <Form.Item
                    name={UB04_FIELDS.procedureDate74d}
                    rules={[
                      getYupValidator(t, UB04_FIELDS.procedureDate74d),
                      getYupWarning(t, UB04_FIELDS.procedureDate74d)
                    ]}
                  >
                    <DatePickerAutoPopulate
                      suffixIcon={null}
                      style={{ width: '100%' }}
                      panelRender={() => false}
                      format={DATE_FORMAT}
                      placeholder={t('common:fields.dateFormat')}
                      name={UB04_FIELDS.procedureDate74d}
                      form={form!}
                    />
                  </Form.Item>
                </div>
                <div className="item_85">
                  <div className="ant-form-item-label">
                    <label>CODE</label>
                  </div>
                  <Form.Item
                    className="field--first"
                    name={UB04_FIELDS.procedureCode74e}
                    rules={[getYupValidator(t, UB04_FIELDS.procedureCode74e)]}
                  >
                    <Input onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())} />
                  </Form.Item>
                </div>
                <div className="item_86">
                  <div className="ant-form-item-label">
                    <label>DATE</label>
                  </div>
                  <Form.Item
                    name={UB04_FIELDS.procedureDate74e}
                    rules={[
                      getYupValidator(t, UB04_FIELDS.procedureDate74e),
                      getYupWarning(t, UB04_FIELDS.procedureDate74e)
                    ]}
                  >
                    <DatePickerAutoPopulate
                      suffixIcon={null}
                      style={{ width: '100%' }}
                      panelRender={() => false}
                      format={DATE_FORMAT}
                      placeholder={t('common:fields.dateFormat')}
                      name={UB04_FIELDS.procedureDate74e}
                      form={form!}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="item123 no-bl">
              {OTHER_PROVIDERS.map((provider, index) => (
                <div key={provider.title} className="padding-5">
                  <label className={`form_label_color ${index ? 'padding_t10' : ''}`}>
                    {` ${76 + index}.`} {provider.title}
                  </label>
                  <FieldsetProviderIds
                    qualifierId={provider.qualifierId}
                    identifierId={provider.identifierId}
                    npiId={provider.npiId}
                    otherType={provider.otherType}
                    index={index}
                  />
                  <FieldsetFullName
                    form="ub04"
                    lastNameId={provider.lastNameId}
                    firstNameId={provider.firstNameId}
                    middleInitialId={provider.middleInitialId}
                    breakPoints={{
                      firstName: 8,
                      lastName: 8,
                      middleInitial: 8
                    }}
                    direction="horizontal"
                  />
                </div>
              ))}
            </div>
            <div className="item122 no-br border-2 no-border">
              <div className="grid-container14 full_height">
                <div className="item_87 padding no-bb bl">
                  <label className="form_label_color">80. {'REMARKS'}</label>
                  <Form.Item name={UB04_FIELDS.remarks} rules={[getYupValidator(t, UB04_FIELDS.remarks)]}>
                    <TextArea
                      autoSize={{ minRows: 4 }}
                      onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                    />
                  </Form.Item>
                </div>
                <div className="item_88 padding no-bb">
                  <label className="form_label_color">81. {'CC'}</label>
                  <FieldsetAdditionalCodes
                    qualifierId={UB04_FIELDS.additionalCodeQualA}
                    codeId={UB04_FIELDS.additionalCodeA}
                    valueId={UB04_FIELDS.additionalCodeValA}
                    labels={{ qualifier: 'qual 1', code: 'code 1', value: 'value 1' }}
                  />

                  <FieldsetAdditionalCodes
                    qualifierId={UB04_FIELDS.additionalCodeQualB}
                    codeId={UB04_FIELDS.additionalCodeB}
                    valueId={UB04_FIELDS.additionalCodeValB}
                    labels={{ qualifier: 'qual 2', code: 'code 2', value: 'value 2' }}
                  />

                  <FieldsetAdditionalCodes
                    qualifierId={UB04_FIELDS.additionalCodeQualC}
                    codeId={UB04_FIELDS.additionalCodeC}
                    valueId={UB04_FIELDS.additionalCodeValC}
                    labels={{ qualifier: 'qual 3', code: 'code 3', value: 'value 3' }}
                  />

                  <FieldsetAdditionalCodes
                    qualifierId={UB04_FIELDS.additionalCodeQualD}
                    codeId={UB04_FIELDS.additionalCodeD}
                    valueId={UB04_FIELDS.additionalCodeValD}
                    labels={{ qualifier: 'qual 4', code: 'code 4', value: 'value 4' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="no-padding"></div>
        </div>
      </Form>
    </div>
  );
};

export default PaperFormUb;
