import {
  test100YearsPast,
  testAmountPaidToTotal,
  testAnesthesiaDuration,
  testDosFromDosToOneYear
} from './customRules';
import { HCFA_FIELDS } from './fields';
import { TFunction } from 'i18next';
import * as yup from 'yup';

export const getHcfaWarningServiceTableShape = (t: TFunction) =>
  yup.object().shape({
    [HCFA_FIELDS.anesthesiaDuration]: yup
      .string()
      .nullable()
      .transform((v, o) => (o === '' ? null : v))
      .test('custom-anesthesiaDuration', t('common:fieldErrors.AnesthesiaDuration'), testAnesthesiaDuration),
    '24a_dos_from': yup
      .string()
      .nullable()
      .test('custom_dos_fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    '24a_dos_to': yup
      .string()
      .nullable()
      .test('custom_dos_fromPast', t('common:fieldErrors.DATE004'), test100YearsPast)
      .test('custom_dos_1year', 'Date Of Service duration dates must be less than one year', testDosFromDosToOneYear),
    [HCFA_FIELDS.renderNPI24]: yup.string().nullable()
    //.test('custom-renderNPI24', 'NPI is not valid', testNPIforLuhnAlghoritm)
  });

export const getHcfaWarningSchema = (t: TFunction) =>
  yup.object().shape({
    [HCFA_FIELDS.servicesTable]: yup.array().of(getHcfaWarningServiceTableShape(t)),

    [HCFA_FIELDS.insuredDateOfBirth]: yup
      .string()
      .nullable()
      .test('custom_dos_fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [HCFA_FIELDS.currentIllness]: yup
      .string()
      .nullable()
      .test('custom_dos_fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [HCFA_FIELDS.otherDate]: yup
      .string()
      .nullable()
      .test('custom_dos_fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [HCFA_FIELDS.insuredSignatureDate]: yup
      .string()
      .nullable()
      .test('custom_dos_fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [HCFA_FIELDS.patientSignedDate]: yup
      .string()
      .nullable()
      .test('custom_dos_fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [HCFA_FIELDS.unableToWorkFromDate]: yup
      .string()
      .nullable()
      .test('custom_dos_fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [HCFA_FIELDS.unableToWorkToDate]: yup
      .string()
      .nullable()
      .test('custom_dos_fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [HCFA_FIELDS.hospitalizationFromDate]: yup
      .string()
      .nullable()
      .test('custom_dos_fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [HCFA_FIELDS.hospitalizationToDate]: yup
      .string()
      .nullable()
      .test('custom_dos_fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    '3_pat_dob': yup.string().nullable().test('custom_dos_fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),
    [HCFA_FIELDS.signatureOfPhysicianDate]: yup
      .string()
      .nullable()
      .test('custom_dos_fromPast', t('common:fieldErrors.DATE004'), test100YearsPast),

    [HCFA_FIELDS.dxCodeA]: yup
      .string()
      .nullable()
      .when(HCFA_FIELDS.icdInd, {
        is: '9',
        then: yup.string().nullable().max(5, t('common:fieldErrors.ICD009'))
      }),
    [HCFA_FIELDS.dxCodeB]: yup
      .string()
      .nullable()
      .when(HCFA_FIELDS.icdInd, {
        is: '9',
        then: yup.string().nullable().max(5, t('common:fieldErrors.ICD009'))
      }),
    [HCFA_FIELDS.dxCodeC]: yup
      .string()
      .nullable()
      .when(HCFA_FIELDS.icdInd, {
        is: '9',
        then: yup.string().nullable().max(5, t('common:fieldErrors.ICD009'))
      }),
    [HCFA_FIELDS.dxCodeD]: yup
      .string()
      .nullable()
      .when(HCFA_FIELDS.icdInd, {
        is: '9',
        then: yup.string().nullable().max(5, t('common:fieldErrors.ICD009'))
      }),
    [HCFA_FIELDS.dxCodeE]: yup
      .string()
      .nullable()
      .when(HCFA_FIELDS.icdInd, {
        is: '9',
        then: yup.string().nullable().max(5, t('common:fieldErrors.ICD009'))
      }),
    [HCFA_FIELDS.dxCodeF]: yup
      .string()
      .nullable()
      .when(HCFA_FIELDS.icdInd, {
        is: '9',
        then: yup.string().nullable().max(5, t('common:fieldErrors.ICD009'))
      }),
    [HCFA_FIELDS.dxCodeG]: yup
      .string()
      .nullable()
      .when(HCFA_FIELDS.icdInd, {
        is: '9',
        then: yup.string().nullable().max(5, t('common:fieldErrors.ICD009'))
      }),
    [HCFA_FIELDS.dxCodeH]: yup
      .string()
      .nullable()
      .when(HCFA_FIELDS.icdInd, {
        is: '9',
        then: yup.string().nullable().max(5, t('common:fieldErrors.ICD009'))
      }),
    [HCFA_FIELDS.dxCodeI]: yup
      .string()
      .nullable()
      .when(HCFA_FIELDS.icdInd, {
        is: '9',
        then: yup.string().nullable().max(5, t('common:fieldErrors.ICD009'))
      }),
    [HCFA_FIELDS.dxCodeJ]: yup
      .string()
      .nullable()
      .when(HCFA_FIELDS.icdInd, {
        is: '9',
        then: yup.string().nullable().max(5, t('common:fieldErrors.ICD009'))
      }),
    [HCFA_FIELDS.dxCodeK]: yup
      .string()
      .nullable()
      .when(HCFA_FIELDS.icdInd, {
        is: '9',
        then: yup.string().nullable().max(5, t('common:fieldErrors.ICD009'))
      }),
    [HCFA_FIELDS.dxCodeL]: yup
      .string()
      .nullable()
      .when(HCFA_FIELDS.icdInd, {
        is: '9',
        then: yup.string().nullable().max(5, t('common:fieldErrors.ICD009'))
      }),

    [HCFA_FIELDS.referringNpi]: yup.string().nullable(),
    ///.test('custom-luhnlogic', 'NPI is not valid', testNPIforLuhnAlghoritm),
    [HCFA_FIELDS.facilityNpi]: yup.string().nullable(),
    ///.test('custom-luhnlogic', 'NPI is not valid', testNPIforLuhnAlghoritm),
    [HCFA_FIELDS.billingNpi]: yup.string().nullable()
    ///.test('custom-luhnlogic', 'NPI is not valid', testNPIforLuhnAlghoritm)
    // [HCFA_FIELDS.billingAddress]: yup
    //   .string()
    //   .nullable()
    //   .test('custom-billingAddressPOBox', 'Physical Address Required for Billing Provider', testPhysicalAddress)
  });

export const knownWarnings = [
  HCFA_FIELDS.insuredDateOfBirth,
  HCFA_FIELDS.currentIllness,
  HCFA_FIELDS.otherDate,
  HCFA_FIELDS.patientSignedDate,
  HCFA_FIELDS.insuredSignatureDate,
  HCFA_FIELDS.unableToWorkFromDate,
  HCFA_FIELDS.unableToWorkToDate,
  HCFA_FIELDS.hospitalizationFromDate,
  HCFA_FIELDS.hospitalizationToDate,
  '3_pat_dob',
  HCFA_FIELDS.signatureOfPhysicianDate,
  HCFA_FIELDS.dxCodeA,
  HCFA_FIELDS.dxCodeB,
  HCFA_FIELDS.dxCodeC,
  HCFA_FIELDS.dxCodeD,
  HCFA_FIELDS.dxCodeE,
  HCFA_FIELDS.dxCodeF,
  HCFA_FIELDS.dxCodeG,
  HCFA_FIELDS.dxCodeH,
  HCFA_FIELDS.dxCodeI,
  HCFA_FIELDS.dxCodeJ,
  HCFA_FIELDS.dxCodeK,
  HCFA_FIELDS.dxCodeL,

  HCFA_FIELDS.referringNpi,
  HCFA_FIELDS.facilityNpi,
  HCFA_FIELDS.billingNpi
  // HCFA_FIELDS.billingAddress
];
