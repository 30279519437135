import { DatePicker, FormInstance, Input } from 'antd';
import { PickerProps } from 'antd/lib/date-picker/generatePicker';
import { NamePath } from 'antd/lib/form/interface';
import moment, { Moment } from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { DATE_FORMAT, DEFAULT_DATE_FORMAT, FALLBACK_DATE_FORMAT } from 'utils/hcfaConstants';
import { Form } from 'antd';
import { FormContext } from './FormProvider';
import { toNum } from '../../helpers/toNum';

type Props = PickerProps<
  Moment & {
    status?: '' | 'warning' | 'error' | undefined;
    dropdownClassName?: string | undefined;
    popupClassName?: string | undefined;
  }
> & {
  form: FormInstance;
  name: NamePath;
  tableName?: '24Table' | '42Services';
  inputId?: string;
  // Implicitly passed by form.item
  onChange?: (value: any) => void;
};

const DatePickerAutoPopulate: React.FC<Props> = (props) => {
  const { form, name, tableName, onChange, inputId, ...rest } = props;

  const dateFormat = (props.format as string | string[]) || DATE_FORMAT;
  const [inputValue, setInputValue] = useState('');
  const path = Array.isArray(name) && tableName ? [tableName, ...name] : name;
  const formValue = Form.useWatch(path, form);

  function triggerChange(value: any) {
    if (onChange) {
      onChange(value);
    }
  }

  useEffect(() => {
    if (!formValue) {
      setInputValue('');
      return;
    }
    const dateFormat = inputValue && inputValue.length === 6 ? FALLBACK_DATE_FORMAT : DEFAULT_DATE_FORMAT;
    if (moment.isMoment(formValue) && formValue.isValid()) {
      const changedValue = moment.utc(formValue).format(dateFormat);

      if (changedValue !== inputValue) {
        setInputValue(changedValue || '');
      }
    }
  }, [formValue]);

  function onDateInputChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const value = ev.target.value;

    setInputValue(value);

    const date = moment.utc(value, dateFormat, true);

    if (form && path) {
      if (value) {
        form.setFieldValue(path, date);
        // form.validateFields([path]);
        // console.log('triggering change ', date);
        triggerChange(date);
      } else {
        form.setFieldValue(path, null);
        // form.validateFields([path]);

        triggerChange(null);
      }
    }
  }

  function inputRender() {
    return (
      <Input
        id={inputId}
        //style={{ width: '70px' }}
        value={inputValue}
        placeholder={props.placeholder || ''}
        onChange={onDateInputChange}
        onBlur={onDateInputChange}
        onInput={toNum}
      />
    );
  }
  return <DatePicker allowClear={false} {...rest} inputRender={inputRender} />;
};

export default DatePickerAutoPopulate;
