import { TestContext } from 'yup';
import { AnyObject } from 'yup/lib/types';
import { UB04_FIELDS } from './fields';
import moment from 'moment';
import { isValidLuhn } from 'helpers/checkLuhnLogic';
import { tomorrowDate } from 'utils/common';
import { DATE_FORMAT } from 'utils/ub04Constants';
import { HCFA33PhysicianEnum } from 'utils/hcfa/constants';
import { getTokenFromSessionStorage } from 'helpers/getTokenFromSessionStorage';
import { condCodeLookup, occurCodeLookup, procedureLookup, revCodeLookup, valueCodeLookup } from 'api/lookups';
import { getFormProvidersDataFromGlobalObject } from 'utils/hcfa/putFormProvidersDataInGlobalObject';
import { checkIsPostBox } from 'helpers/checkIsPostBox';
import { aphaNumWithHyphensRegex } from '../hcfa/validations';

type ValueType = any | string | null | undefined;
type ContextType = TestContext<AnyObject>;
export type HcfaTableChange = [...undefined[], Record<string, string>];
export type HcfaFormValueChange = Record<string, string | HcfaTableChange>;

const ValuesToTestEmptyProvider = ['72', 'ZZ'];

const ValidationsDependenciesMap = {
  [UB04_FIELDS.patientFirstName]: [UB04_FIELDS.patRelInfo6],
  [UB04_FIELDS.patientLastName]: [UB04_FIELDS.patRelInfo6],
  [UB04_FIELDS.payToAddress]: [UB04_FIELDS.payToCity, UB04_FIELDS.payToState, UB04_FIELDS.payToZip],
  [UB04_FIELDS.coversPeriodFrom]: [
    UB04_FIELDS.coversPeriodTo,
    UB04_FIELDS.patientBirthdate,
    UB04_FIELDS.payer50A,
    UB04_FIELDS.payer50B,
    UB04_FIELDS.payer50C,
    UB04_FIELDS.admissionDate
  ],
  [UB04_FIELDS.patientBirthdate]: [
    UB04_FIELDS.coversPeriodFrom,
    UB04_FIELDS.coversPeriodTo,
    UB04_FIELDS.occurrenceSpan35AFrom,
    UB04_FIELDS.occurrenceSpan35ATo,
    UB04_FIELDS.occurrenceSpan35BFrom,
    UB04_FIELDS.occurrenceSpan35BTo,
    UB04_FIELDS.occurrenceSpan36AFrom,
    UB04_FIELDS.occurrenceSpan36ATo,
    UB04_FIELDS.occurrenceSpan36BFrom,
    UB04_FIELDS.occurrenceSpan36BTo,
    UB04_FIELDS.principalProcedureDate,
    UB04_FIELDS.procedureDate74a,
    UB04_FIELDS.procedureDate74b,
    UB04_FIELDS.procedureDate74c,
    UB04_FIELDS.procedureDate74d,
    UB04_FIELDS.procedureDate74e
  ],
  [UB04_FIELDS.coversPeriodTo]: [UB04_FIELDS.patientBirthdate, UB04_FIELDS.coversPeriodFrom, UB04_FIELDS.admissionDate],
  [UB04_FIELDS.patientAddress]: [UB04_FIELDS.patientCity, UB04_FIELDS.patientState, UB04_FIELDS.patientZip],
  [UB04_FIELDS.typeOfBill]: [
    UB04_FIELDS.admissionDate,
    UB04_FIELDS.admissionHour,
    UB04_FIELDS.dischargeHour,
    UB04_FIELDS.documentControlNumber64a
  ],
  [UB04_FIELDS.responsiblePartyAddress]: [
    UB04_FIELDS.responsiblePartyCity,
    UB04_FIELDS.responsiblePartyState,
    UB04_FIELDS.responsiblePartyZip
  ],
  [UB04_FIELDS.otherProvider1LastName]: [
    UB04_FIELDS.otherProvider1FirstName,
    UB04_FIELDS.otherProvider1Npi,
    UB04_FIELDS.otherProvider1Id,
    UB04_FIELDS.operatingProviderLastName,
    UB04_FIELDS.otherType78,
    UB04_FIELDS.otherType79
  ],
  [UB04_FIELDS.otherProvider1FirstName]: [
    UB04_FIELDS.operatingProviderFirst,
    UB04_FIELDS.otherProvider1LastName,
    UB04_FIELDS.otherProvider1Id,
    UB04_FIELDS.otherProvider1Npi,
    UB04_FIELDS.otherType78,
    UB04_FIELDS.otherType79
  ],
  [UB04_FIELDS.otherProvider2FirstName]: [
    UB04_FIELDS.operatingProviderFirst,
    UB04_FIELDS.otherProvider1FirstName,
    UB04_FIELDS.otherProvider2Id,
    UB04_FIELDS.otherProvider2Npi
  ],
  [UB04_FIELDS.otherProvider1Npi]: [
    UB04_FIELDS.otherProvider1LastName,
    UB04_FIELDS.otherProvider1FirstName,
    UB04_FIELDS.operatingProviderNpi,
    UB04_FIELDS.otherProvider1Id,
    UB04_FIELDS.otherType78,
    UB04_FIELDS.otherType79
  ],
  [UB04_FIELDS.operatingProviderNpi]: [
    UB04_FIELDS.otherProvider1LastName,
    UB04_FIELDS.otherProvider2LastName,
    UB04_FIELDS.operatingProviderLastName,
    UB04_FIELDS.operatingProviderFirstName,
    UB04_FIELDS.operatingProviderId
  ],
  [UB04_FIELDS.otherProvider2LastName]: [
    UB04_FIELDS.otherProvider2FirstName,
    UB04_FIELDS.otherProvider2Npi,
    UB04_FIELDS.otherProvider2Id,
    UB04_FIELDS.otherProvider1LastName,
    UB04_FIELDS.operatingProviderLastName,
    UB04_FIELDS.otherType78,
    UB04_FIELDS.otherType79
  ],
  [UB04_FIELDS.otherProvider2Npi]: [
    UB04_FIELDS.otherProvider2LastName,
    UB04_FIELDS.otherProvider2FirstName,
    UB04_FIELDS.otherProvider2Id,
    UB04_FIELDS.otherProvider1Npi,
    UB04_FIELDS.operatingProviderNpi
  ],
  [UB04_FIELDS.attendingProviderLastName]: [UB04_FIELDS.attendingProviderNpi, UB04_FIELDS.attendingProviderId],
  [UB04_FIELDS.attendingProviderFirstName]: [UB04_FIELDS.attendingProviderNpi, UB04_FIELDS.attendingProviderId],

  [UB04_FIELDS.attendingProviderId]: [
    UB04_FIELDS.attendingProviderNpi,
    UB04_FIELDS.attendingProviderLastName,
    UB04_FIELDS.attendingProviderFirstName,
    UB04_FIELDS.attendingProviderQual
  ],
  [UB04_FIELDS.attendingProviderNpi]: [
    UB04_FIELDS.attendingProviderId,
    UB04_FIELDS.attendingProviderLastName,
    UB04_FIELDS.attendingProviderFirstName
  ],
  [UB04_FIELDS.operatingProviderLastName]: [UB04_FIELDS.operatingProviderNpi, UB04_FIELDS.operatingProviderId],
  [UB04_FIELDS.operatingProviderFirstName]: [UB04_FIELDS.operatingProviderNpi, UB04_FIELDS.operatingProviderId],
  [UB04_FIELDS.dos_45]: [UB04_FIELDS.coversPeriodFrom, UB04_FIELDS.coversPerioTo],
  [UB04_FIELDS.codeQualifier]: [
    UB04_FIELDS.code67aDx,
    UB04_FIELDS.code67bDx,
    UB04_FIELDS.code67cDx,
    UB04_FIELDS.code67dDx,
    UB04_FIELDS.code67eDx,
    UB04_FIELDS.code67fDx,
    UB04_FIELDS.code67gDx,
    UB04_FIELDS.code67hDx,
    UB04_FIELDS.code67iDx,
    UB04_FIELDS.code67jDx,
    UB04_FIELDS.code67kDx,
    UB04_FIELDS.code67lDx,
    UB04_FIELDS.code67mDx,
    UB04_FIELDS.code67nDx,
    UB04_FIELDS.code67oDx,
    UB04_FIELDS.code67pDx,
    UB04_FIELDS.code67qDx
  ],
  [UB04_FIELDS.value39ACode]: [UB04_FIELDS.value39AAmount],
  [UB04_FIELDS.value39BCode]: [UB04_FIELDS.value39BAmount],
  [UB04_FIELDS.value39CCode]: [UB04_FIELDS.value39VAmount],
  [UB04_FIELDS.value39DCode]: [UB04_FIELDS.value39DAmount],
  [UB04_FIELDS.value40ACode]: [UB04_FIELDS.value40AAmount],
  [UB04_FIELDS.value40BCode]: [UB04_FIELDS.value40BAmount],
  [UB04_FIELDS.value40CCode]: [UB04_FIELDS.value40CAmount],
  [UB04_FIELDS.value40DCode]: [UB04_FIELDS.value40DAmount],
  [UB04_FIELDS.value41ACode]: [UB04_FIELDS.value41AAmount],
  [UB04_FIELDS.value41BCode]: [UB04_FIELDS.value41BAmount],
  [UB04_FIELDS.value41CCode]: [UB04_FIELDS.value41VAmount],
  [UB04_FIELDS.value41DCode]: [UB04_FIELDS.value41DAmount],
  [UB04_FIELDS.ocurrenceSpan35AFrom]: [UB04_FIELDS.ocurrenceSpan35ATo],

  [UB04_FIELDS.occurrenceSpan35ACode]: [UB04_FIELDS.occurrenceSpan35AFrom, UB04_FIELDS.occurrenceSpan35ATo],
  [UB04_FIELDS.occurrenceSpan35BCode]: [UB04_FIELDS.occurrenceSpan35BFrom, UB04_FIELDS.occurrenceSpan35BTo],
  [UB04_FIELDS.occurrenceSpan36ACode]: [UB04_FIELDS.occurrenceSpan36AFrom, UB04_FIELDS.occurrenceSpan36ATo],
  [UB04_FIELDS.occurrenceSpan36BCode]: [UB04_FIELDS.occurrenceSpan36BFrom, UB04_FIELDS.occurrenceSpan36BTo],

  [UB04_FIELDS.ocurrenceSpan35BFrom]: [UB04_FIELDS.ocurrenceSpan35BTo],
  [UB04_FIELDS.ocurrenceSpan36AFrom]: [UB04_FIELDS.ocurrenceSpan36ATo],
  [UB04_FIELDS.ocurrenceSpan36BFrom]: [UB04_FIELDS.ocurrenceSpan36BTo],
  //[UB04_FIELDS.attendingProviderId]: [UB04_FIELDS.attendingProviderQual],
  [UB04_FIELDS.operatingProviderId]: [UB04_FIELDS.operatingProviderQual, UB04_FIELDS.operatingProviderNpi],
  [UB04_FIELDS.otherProvider1Id]: [UB04_FIELDS.otherProvider1Qual, UB04_FIELDS.otherProvider1Npi],
  [UB04_FIELDS.otherProvider2Id]: [UB04_FIELDS.otherProvider2Qual, UB04_FIELDS.otherProvider2Npi],
  [UB04_FIELDS.attendingProviderQual]: [UB04_FIELDS.attendingProviderId],
  [UB04_FIELDS.operatingProviderQual]: [UB04_FIELDS.operatingProviderId],
  [UB04_FIELDS.otherProvider1Qual]: [UB04_FIELDS.otherProvider1Id, UB04_FIELDS.operatingProviderQual],
  [UB04_FIELDS.otherProvider2Qual]: [UB04_FIELDS.otherProvider2Id, UB04_FIELDS.operatingProviderQual],
  [UB04_FIELDS.principalProcedureCode]: [UB04_FIELDS.principalProcedureDate],
  [UB04_FIELDS.procedureCode74a]: [UB04_FIELDS.procedureDate74a],
  [UB04_FIELDS.procedureCode74b]: [UB04_FIELDS.procedureDate74b],
  [UB04_FIELDS.procedureCode74c]: [UB04_FIELDS.procedureDate74c],
  [UB04_FIELDS.procedureCode74d]: [UB04_FIELDS.procedureDate74d],
  [UB04_FIELDS.procedureCode74e]: [UB04_FIELDS.procedureDate74e],
  // [UB04_FIELDS.insuredState]: [UB04_FIELDS.billingAddress],
  [UB04_FIELDS.patientState]: [UB04_FIELDS.billingAddress],
  [UB04_FIELDS.billing_addr2]: [UB04_FIELDS.billingAddress],
  [UB04_FIELDS.modifier_1]: [
    UB04_FIELDS.modifier_2,
    UB04_FIELDS.modifier_3,
    UB04_FIELDS.modifier_4,
    UB04_FIELDS.modifier_5,
    UB04_FIELDS.modifier_6,
    UB04_FIELDS.modifier_7,
    UB04_FIELDS.modifier_8,
    UB04_FIELDS.modifier_9,
    UB04_FIELDS.modifier_10
  ],
  [UB04_FIELDS.modifier_2]: [
    UB04_FIELDS.modifier_1,
    UB04_FIELDS.modifier_3,
    UB04_FIELDS.modifier_4,
    UB04_FIELDS.modifier_5,
    UB04_FIELDS.modifier_6,
    UB04_FIELDS.modifier_7,
    UB04_FIELDS.modifier_8,
    UB04_FIELDS.modifier_9,
    UB04_FIELDS.modifier_10
  ],
  [UB04_FIELDS.modifier_3]: [
    UB04_FIELDS.modifier_1,
    UB04_FIELDS.modifier_2,
    UB04_FIELDS.modifier_4,
    UB04_FIELDS.modifier_5,
    UB04_FIELDS.modifier_6,
    UB04_FIELDS.modifier_7,
    UB04_FIELDS.modifier_8,
    UB04_FIELDS.modifier_9,
    UB04_FIELDS.modifier_10
  ],
  [UB04_FIELDS.modifier_4]: [
    UB04_FIELDS.modifier_1,
    UB04_FIELDS.modifier_2,
    UB04_FIELDS.modifier_3,
    UB04_FIELDS.modifier_5,
    UB04_FIELDS.modifier_6,
    UB04_FIELDS.modifier_7,
    UB04_FIELDS.modifier_8,
    UB04_FIELDS.modifier_9,
    UB04_FIELDS.modifier_10
  ],
  [UB04_FIELDS.modifier_5]: [
    UB04_FIELDS.modifier_1,
    UB04_FIELDS.modifier_2,
    UB04_FIELDS.modifier_3,
    UB04_FIELDS.modifier_4,
    UB04_FIELDS.modifier_6,
    UB04_FIELDS.modifier_7,
    UB04_FIELDS.modifier_8,
    UB04_FIELDS.modifier_9,
    UB04_FIELDS.modifier_10
  ],
  [UB04_FIELDS.modifier_6]: [
    UB04_FIELDS.modifier_1,
    UB04_FIELDS.modifier_2,
    UB04_FIELDS.modifier_3,
    UB04_FIELDS.modifier_4,
    UB04_FIELDS.modifier_5,
    UB04_FIELDS.modifier_7,
    UB04_FIELDS.modifier_8,
    UB04_FIELDS.modifier_9,
    UB04_FIELDS.modifier_10
  ],
  [UB04_FIELDS.modifier_7]: [
    UB04_FIELDS.modifier_1,
    UB04_FIELDS.modifier_2,
    UB04_FIELDS.modifier_3,
    UB04_FIELDS.modifier_4,
    UB04_FIELDS.modifier_5,
    UB04_FIELDS.modifier_6,
    UB04_FIELDS.modifier_8,
    UB04_FIELDS.modifier_9,
    UB04_FIELDS.modifier_10
  ],
  [UB04_FIELDS.modifier_8]: [
    UB04_FIELDS.modifier_1,
    UB04_FIELDS.modifier_2,
    UB04_FIELDS.modifier_3,
    UB04_FIELDS.modifier_4,
    UB04_FIELDS.modifier_5,
    UB04_FIELDS.modifier_6,
    UB04_FIELDS.modifier_7,
    UB04_FIELDS.modifier_9,
    UB04_FIELDS.modifier_10
  ],
  [UB04_FIELDS.modifier_9]: [
    UB04_FIELDS.modifier_1,
    UB04_FIELDS.modifier_2,
    UB04_FIELDS.modifier_3,
    UB04_FIELDS.modifier_4,
    UB04_FIELDS.modifier_5,
    UB04_FIELDS.modifier_6,
    UB04_FIELDS.modifier_7,
    UB04_FIELDS.modifier_8,
    UB04_FIELDS.modifier_10
  ],
  [UB04_FIELDS.modifier_10]: [
    UB04_FIELDS.modifier_1,
    UB04_FIELDS.modifier_2,
    UB04_FIELDS.modifier_3,
    UB04_FIELDS.modifier_4,
    UB04_FIELDS.modifier_5,
    UB04_FIELDS.modifier_6,
    UB04_FIELDS.modifier_7,
    UB04_FIELDS.modifier_8,
    UB04_FIELDS.modifier_9
  ],
  [UB04_FIELDS.billingNpi]: [UB04_FIELDS.billingName],
  [UB04_FIELDS.billingName]: [UB04_FIELDS.billingNpi],
  [UB04_FIELDS.ub_total_charges_sum]: [
    UB04_FIELDS.estimatedAmount55A,
    UB04_FIELDS.estimatedAmount55B,
    UB04_FIELDS.estimatedAmount55C
  ],
  [UB04_FIELDS.estimatedAmount55A]: [UB04_FIELDS.ub_total_charges_sum],
  [UB04_FIELDS.estimatedAmount55B]: [UB04_FIELDS.ub_total_charges_sum],
  [UB04_FIELDS.estimatedAmount55C]: [UB04_FIELDS.ub_total_charges_sum],
  [UB04_FIELDS.payer50A]: [
    UB04_FIELDS.healthPlan51A,
    UB04_FIELDS.insured58aLastName,
    UB04_FIELDS.insured58aFirstName,
    UB04_FIELDS.insured60aUniqueId,
    UB04_FIELDS.coversPeriodFrom,
    UB04_FIELDS.payer50B,
    UB04_FIELDS.payer50C,
    UB04_FIELDS.insured58bLastName,
    UB04_FIELDS.insured58bFirstName,
    UB04_FIELDS.insured60bUniqueId,
    UB04_FIELDS.insured58cLastName,
    UB04_FIELDS.insured58cFirstName,
    UB04_FIELDS.insured60cUniqueId,
    UB04_FIELDS.documentControlNumber64a,
    UB04_FIELDS.documentControlNumber64b,
    UB04_FIELDS.documentControlNumber64c
  ],
  [UB04_FIELDS.payer50B]: [
    UB04_FIELDS.healthPlan51B,
    UB04_FIELDS.insured58bLastName,
    UB04_FIELDS.insured58bFirstName,
    UB04_FIELDS.insured60bUniqueId,
    UB04_FIELDS.payer50A,
    UB04_FIELDS.payer50C,
    UB04_FIELDS.insured58aLastName,
    UB04_FIELDS.insured58aFirstName,
    UB04_FIELDS.insured60aUniqueId,
    UB04_FIELDS.insured58cLastName,
    UB04_FIELDS.insured58cFirstName,
    UB04_FIELDS.insured60cUniqueId,
    UB04_FIELDS.documentControlNumber64a,
    UB04_FIELDS.documentControlNumber64b,
    UB04_FIELDS.documentControlNumber64c
  ],
  [UB04_FIELDS.payer50C]: [
    UB04_FIELDS.healthPlan51C,
    UB04_FIELDS.insured58cLastName,
    UB04_FIELDS.insured58cFirstName,
    UB04_FIELDS.insured60cUniqueId,
    UB04_FIELDS.payer50A,
    UB04_FIELDS.payer50B,
    UB04_FIELDS.insured58aLastName,
    UB04_FIELDS.insured58aFirstName,
    UB04_FIELDS.insured60aUniqueId,
    UB04_FIELDS.insured58bLastName,
    UB04_FIELDS.insured58bFirstName,
    UB04_FIELDS.insured60bUniqueId,
    UB04_FIELDS.documentControlNumber64a,
    UB04_FIELDS.documentControlNumber64b,
    UB04_FIELDS.documentControlNumber64c
  ],
  [UB04_FIELDS.otherType78]: [
    UB04_FIELDS.otherType79,
    UB04_FIELDS.otherProvider1Npi,
    UB04_FIELDS.otherProvider2Npi,
    UB04_FIELDS.otherProvider1LastName,
    UB04_FIELDS.otherProvider2LastName,
    UB04_FIELDS.otherProvider1FirstName,
    UB04_FIELDS.otherProvider2FirstName
  ],
  [UB04_FIELDS.otherType79]: [
    UB04_FIELDS.otherType78,
    UB04_FIELDS.otherProvider1Npi,
    UB04_FIELDS.otherProvider2Npi,
    UB04_FIELDS.otherProvider1LastName,
    UB04_FIELDS.otherProvider2LastName,
    UB04_FIELDS.otherProvider1FirstName,
    UB04_FIELDS.otherProvider2FirstName
  ],

  [UB04_FIELDS.occurrenceSpan35AFrom]: [
    UB04_FIELDS.patientBirthdate,
    UB04_FIELDS.occurrenceSpan35ACode,
    UB04_FIELDS.occurrenceSpan35ATo
  ],
  [UB04_FIELDS.occurrenceSpan35ATo]: [UB04_FIELDS.patientBirthdate, UB04_FIELDS.occurrenceSpan35ACode],

  [UB04_FIELDS.occurrenceSpan35BFrom]: [
    UB04_FIELDS.patientBirthdate,
    UB04_FIELDS.occurrenceSpan35BCode,
    UB04_FIELDS.occurrenceSpan35BTo
  ],
  [UB04_FIELDS.occurrenceSpan35BTo]: [UB04_FIELDS.patientBirthdate, UB04_FIELDS.occurrenceSpan35BCode],

  [UB04_FIELDS.occurrenceSpan36AFrom]: [
    UB04_FIELDS.patientBirthdate,
    UB04_FIELDS.occurrenceSpan36ACode,
    UB04_FIELDS.occurrenceSpan36ATo
  ],
  [UB04_FIELDS.occurrenceSpan36ATo]: [UB04_FIELDS.patientBirthdate, UB04_FIELDS.occurrenceSpan36ACode],

  [UB04_FIELDS.occurrenceSpan36BFrom]: [
    UB04_FIELDS.patientBirthdate,
    UB04_FIELDS.occurrenceSpan36BCode,
    UB04_FIELDS.occurrenceSpan36BTo
  ],
  [UB04_FIELDS.occurrenceSpan36BTo]: [UB04_FIELDS.patientBirthdate, UB04_FIELDS.occurrenceSpan35BCode],

  [UB04_FIELDS.principalProcedureDate]: [UB04_FIELDS.patientBirthdate],
  [UB04_FIELDS.procedureDate74a]: [UB04_FIELDS.patientBirthdate],
  [UB04_FIELDS.procedureDate74b]: [UB04_FIELDS.patientBirthdate],
  [UB04_FIELDS.procedureDate74c]: [UB04_FIELDS.patientBirthdate],
  [UB04_FIELDS.procedureDate74d]: [UB04_FIELDS.patientBirthdate],
  [UB04_FIELDS.procedureDate74e]: [UB04_FIELDS.patientBirthdate]
};

const ValidationTableDependenciesMap = {
  '24j_render_id': ['24g_units', '24i_render_id_qual'],
  '24a_dos_from': ['24a_dos_to'],
  [UB04_FIELDS.desc_ndc_code]: [UB04_FIELDS.desc_ndc_qty, UB04_FIELDS.desc_ndc_unit],
  [UB04_FIELDS.total_charges_47]: [UB04_FIELDS.nonCoveredCharges_48],
  [UB04_FIELDS.nonCoveredCharges_48]: [UB04_FIELDS.total_charges_47],
  [UB04_FIELDS.desc_ndc_unit]: [UB04_FIELDS.desc_ndc_qty],
  [UB04_FIELDS.desc_ndc_qty]: [UB04_FIELDS.desc_ndc_unit]
};
/**get index for 42Services[index].fieldName */
function extractIndexFor42ServicesYup(inputString: string): number | null {
  const regexPattern = /42Services\[(\d+)\]\.\w+/;

  const match = inputString.match(regexPattern);

  if (match && match[1]) {
    return parseInt(match[1], 10);
  } else {
    return null;
  }
}
/**get index for 42Services.index.fieldName */
function extractIndexFor42ServicesAntd(modelString: string): number | null {
  const regex = /^42Services\.(\d+)\..+$/;
  const match = modelString.match(regex);

  if (match && match[1]) {
    const index = parseInt(match[1], 10);
    return isNaN(index) ? null : index;
  }

  return null;
}

function getFormValues(context: ContextType) {
  const data = getFormProvidersDataFromGlobalObject();
  return data ? data : context.parent;
}

function getDestinationPayer(payer50A: string, payer50B: string, payer50C: string) {
  const formValues = getFormProvidersDataFromGlobalObject();
  const coversFromDate = formValues?.[UB04_FIELDS.coversPeriodFrom];
  const predefinedPayersArr = ['CARESOURCE', 'CARE SOURCE', 'COMMONGROUND', 'COMMON GROUND'];
  if (coversFromDate) {
    const coversFromDateParsed = moment(coversFromDate);

    const end19Date = moment.utc('12312019', 'MMDDYYYY').endOf('day');
    if (coversFromDateParsed.isValid() && coversFromDateParsed.isSameOrBefore(end19Date)) {
      predefinedPayersArr.push(
        'HUMANA CARESOURCE',
        'HUMANA CARE SOURCE',
        'HUMANA MEDICAID',
        'HUMANA MEDICAID CARESOURCE',
        'HUMANA MEDICAID CARE SOURCE'
      );
    }
  }

  if (payer50A && predefinedPayersArr.some((payer) => payer50A.toUpperCase().includes(payer))) return 'A';
  if (payer50B && predefinedPayersArr.some((payer) => payer50B.toUpperCase().includes(payer))) return 'B';
  if (payer50C && predefinedPayersArr.some((payer) => payer50C.toUpperCase().includes(payer))) return 'C';

  if (payer50C) {
    return 'C';
  }
  if (payer50B) {
    return 'B';
  }
  if (payer50A) {
    return 'A';
  }
  return undefined;
}

function testInsured58bLastName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[UB04_FIELDS.insured58bLastName];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}
function testInsured58bFirstName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[UB04_FIELDS.insured58bFirstName];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}
function testInsured58cLastName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[UB04_FIELDS.insured58cLastName];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}
function testInsured58cFirstName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[UB04_FIELDS.insured58cFirstName];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testProviderNameToSupplier(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const physicianValue = formValues[UB04_FIELDS.HCFA33psysiciancheckbox];
  const isSupplier =
    physicianValue &&
    typeof physicianValue === 'string' &&
    physicianValue.toLowerCase() === HCFA33PhysicianEnum.supplier;
  if (!isSupplier) return true;
  return Boolean(value);
}

function testProviderNameToPhysician(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const physicianValue = formValues[UB04_FIELDS.HCFA33psysiciancheckbox];
  const isPhysician =
    physicianValue && typeof physicianValue === 'string' && physicianValue.toLowerCase() === 'physician';
  if (!isPhysician) return true;
  return Boolean(value);
}

function testCodeQualifier(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[UB04_FIELDS.codeQualifier];
  if (!relationValue) {
    return true;
  }

  return Boolean(value);
}

function testTomorrowsDate(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const relationValue = formValues[UB04_FIELDS.occurrenceDate31A];
  const tmrrwDate = tomorrowDate(DATE_FORMAT[0]);
  if (!(relationValue === tmrrwDate)) {
    return true;
  }

  return Boolean(value);
}

function testTotalChargeToTableFields(value: ValueType, context: ContextType) {
  if (!value) return true;
  const valueParsed = parseFloat(value);
  if (isNaN(valueParsed)) return true;
  const formValues = getFormValues(context);
  const tableValues: any[] = formValues['42Services'];
  if (!tableValues && !Array.isArray(tableValues)) return valueParsed == 0;
  let sum = 0;
  tableValues.forEach((row) => {
    const chargeValue = row?.[UB04_FIELDS.chargesf24];
    if (chargeValue && !isNaN(parseFloat(chargeValue))) {
      sum += parseFloat(chargeValue);
    }
  });
  return valueParsed == sum;
}

function testBillingAddress(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const physicianValue = formValues[UB04_FIELDS.billingAddress];
  if (!physicianValue) return true;
  return Boolean(value);
}

function testPayToAddress(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.payToAddress];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testTypeOfBill3Char(value: ValueType, context: ContextType) {
  const regexPattern = /^[a-zA-Z1-9]{2}[a-zA-Z0-9]$/;
  if (value?.length === 3) return value.match(regexPattern);
  return true;
}

function testTypeOfBill4Char(value: ValueType, context: ContextType) {
  const regexPattern = /^0[a-zA-Z1-9]{2}[a-zA-Z0-9]$/;
  if (value?.length === 4) return value.match(regexPattern);
  return true;
}

function testFutureDate(value: ValueType, context: ContextType) {
  if (!value) return true;
  const date = moment(value);
  if (!date.isValid()) return true;
  const today = moment(Date.now()).endOf('day');
  return date.isSameOrBefore(today);
}

export const test100YearsPast = (value: ValueType) => {
  if (!value) return true;
  const _value = moment(value, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ', true);
  const oneHundredYearsAgo = moment().subtract(100, 'years');
  if (!_value.isValid()) return true;
  return _value.isSameOrAfter(oneHundredYearsAgo);
};

function testEarlierCoversPeriodFrom(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);

  const fromValue = formValues[UB04_FIELDS.coversPeriodFrom];
  if (!fromValue) return true;
  const fromDate = moment(fromValue).startOf('day');

  const toValue = formValues[UB04_FIELDS.coversPeriodTo];
  if (!fromValue) return true;
  const toDate = moment(toValue).startOf('day');

  if (!toDate.isValid()) return true;
  if (!fromDate.isValid()) return true;
  if (fromDate.isSame(toDate, 'day')) return true;

  return fromDate.isBefore(toDate);
}

function testEarlierPatientDOB(value: ValueType, context: ContextType, values?: any) {
  if (!value) return true;
  const date = moment.utc(value).startOf('day');
  if (!date.isValid()) return true;
  const formValues = values ?? getFormValues(context);
  const patientDOB = formValues[UB04_FIELDS.patientBirthdate];
  if (!patientDOB) return true;
  const patienDOBDate = moment(patientDOB).startOf('day');
  if (!patienDOBDate.isValid()) return true;

  return patienDOBDate.isSameOrBefore(date);
}

function testEarlierPatientDOBById(value: ValueType, context: ContextType, passedValue: any) {
  if (!value) return true;
  if (!passedValue) return true;

  const formValues = getFormValues(context);

  const fromValue = formValues[UB04_FIELDS.patientBirthdate];
  if (!fromValue) return true;
  const fromDate = moment(fromValue).startOf('day');

  const toValue = formValues[passedValue];
  if (!fromValue) return true;
  const toDate = moment(toValue).startOf('day');

  if (!toDate.isValid()) return true;
  if (!fromDate.isValid()) return true;
  if (fromDate.isSame(toDate, 'day')) return true;

  return fromDate.isBefore(toDate);
}

function testMoreThan1Year(value: ValueType, context: ContextType) {
  if (!value) return true;
  const yearAgo = moment(new Date()).subtract(1, 'years').startOf('day');
  const date = moment(value).startOf('day');
  if (!date.isValid()) return true;
  return yearAgo.isSameOrBefore(date);
}

function testLaterThanServiceDate(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[context.path] || value;
  if (!fieldValue) return true;
  const date = moment(fieldValue).startOf('day');
  if (!date.isValid()) return true;

  const tableDates = formValues[UB04_FIELDS.servicesList]
    ?.map((row: any) => {
      return row[UB04_FIELDS.dos_45] ? moment(row[UB04_FIELDS.dos_45]).startOf('day') : undefined;
    })
    .filter(Boolean);
  if (!tableDates?.length) return true;
  const min = moment.min(tableDates);

  if (!min) return true;
  // const serviceDate = moment(serviceDateField).startOf('day');
  if (!min.isValid()) return true;

  return min.isSameOrAfter(date);
}

function testAfterThanServiceDate(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[context.path] || value;
  if (!fieldValue) return true;
  const date = moment(fieldValue).startOf('day');
  if (!date.isValid()) return true;

  const tableDates = formValues[UB04_FIELDS.servicesList]
    ?.map((row: any) => {
      return row[UB04_FIELDS.dos_45] ? moment(row[UB04_FIELDS.dos_45]).startOf('day') : undefined;
    })
    .filter(Boolean);
  if (!tableDates?.length) return true;
  const max = moment.max(tableDates);

  if (!max) return true;
  // const serviceDate = moment(serviceDateField).startOf('day');
  if (!max.isValid()) return true;

  return max.isSameOrBefore(date);
}

function fromDataConditionedByBox50Fields(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const payer50A = formValues[UB04_FIELDS.payer50A];
  const payer50B = formValues[UB04_FIELDS.payer50B];
  const payer50C = formValues[UB04_FIELDS.payer50C];

  const regex = /.*Humana CareSource.*|.*Humana Medicaid CareSource.*|.*Humana Medicaid.*/i;

  const match50A = payer50A?.match(regex);
  const match50B = payer50B?.match(regex);
  const match50C = payer50C?.match(regex);

  const isBeforeStartingDate = value ? moment(value).startOf('day') : null;
  const startingDate = moment('12/31/2019', 'MM/DD/YYYY').startOf('day');

  if (match50A || match50B || match50C) {
    if (isBeforeStartingDate && isBeforeStartingDate.isAfter(startingDate)) {
      return false;
    } else {
      return true;
    }
  }

  return true;
}

// function testPayerRegexCheck(value: ValueType, context: ContextType) {
//   const formValues = getFormValues(context);
//   const payer50A = formValues[UB04_FIELDS.payer50A];
//   const payer50B = formValues[UB04_FIELDS.payer50B];
//   const payer50C = formValues[UB04_FIELDS.payer50C];

//   const regex =
//     /.*CARE SOURCE.*|^CS$|.*CareSource.*|.*CareSourc.*|.*CareSour.*|.*CareSou.*|.*CSOURCE.*|.*CareSource.*|.*CareSourc.*|.*CareSour.*|.*CareSou.*|.*CSOURCE.*|.*CSGA.*|.*CSHIP.*|.*CSIN.*|.*CSKY.*|.*CSOH.*|.*CSOHIO.*|.*HIXGA.*|.*HIXIN.*|.*HIXKY.*|.*HIXOH.*|.*HIXWV.*|.*Care Source.*|.*CS GA.*|.*CS HIP.*|.*CS IN.*|.*CS KY.*|.*CS OH.*|.*CS OHIO.*|.*HIX GA.*|.*HIX IN.*|.*HIX KY.*|.*HIX OH.*|.*HIX WV.*|.*MEDICAIDOH MEDICAID.*|.*MEDICAID OHIO.*|.*Ohio Medicaid.*/i;

//   const match50A = payer50A?.match(regex);
//   const match50B = payer50B?.match(regex);
//   const match50C = payer50C?.match(regex);

//   if (!value) {
//     return true;
//   }

//   if (match50A || match50B || match50C) {
//     return true;
//   }

//   return false;
// }

function testOtherPayer(value: ValueType, context: ContextType, firstPayer: any, secondPayer: any) {
  const formValues = getFormValues(context);
  const first = formValues[firstPayer];
  const second = formValues[secondPayer];
  const third = value;

  // At least 1 is filled - all good don't check regexes
  if ((first && !second && !third) || (!first && second && !third) || (!first && !second && third)) {
    return true;
  }

  // no fields are filled - throw an error
  if (!first && !second && !third) {
    return false;
  }

  // More than two fields are filled
  if ((first && second && !third) || (first && !second && third) || (!first && second && third)) {
    const regex2 =
      /.*CARE SOURCE.*|^CS$|.*CareSource.*|.*CareSourc.*|.*CareSour.*|.*CareSou.*|.*CSOURCE.*|.*CareSource.*|.*CareSourc.*|.*CareSour.*|.*CareSou.*|.*CSOURCE.*|.*CSGA.*|.*CSHIP.*|.*CSIN.*|.*CSKY.*|.*CSOH.*|.*CSOHIO.*|.*HIXGA.*|.*HIXIN.*|.*HIXKY.*|.*HIXOH.*|.*HIXWV.*|.*Care Source.*|.*CS GA.*|.*CS HIP.*|.*CS IN.*|.*CS KY.*|.*CS OH.*|.*CS OHIO.*|.*HIX GA.*|.*HIX IN.*|.*HIX KY.*|.*HIX OH.*|.*HIX WV.*|.*MEDICAIDOH MEDICAID.*|.*MEDICAID OHIO.*|.*Ohio Medicaid.*|.*HAP.*|.*COMMON GROUND.*|.*COMMONGROUND.*/i;

    const atLeastOneMeetsRegex2 =
      (first && second && !third && (regex2.test(first) || regex2.test(second))) ||
      (first && !second && third && (regex2.test(first) || regex2.test(third))) ||
      (!first && second && third && (regex2.test(second) || regex2.test(third)));

    if (!atLeastOneMeetsRegex2) {
      return false;
    }

    return true;
  }

  return true;
}

function testLaterThanOccurence35SpanDate(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);

  const fromValue = formValues[UB04_FIELDS.occurrenceSpan35AFrom];
  if (!fromValue) return true;
  const fromDate = moment(fromValue).startOf('day');

  const toValue = formValues[UB04_FIELDS.occurrenceSpan35ATo];
  if (!fromValue) return true;
  const toDate = moment(toValue).startOf('day');

  if (!toDate.isValid()) return true;
  if (!fromDate.isValid()) return true;
  if (fromDate.isSame(toDate, 'day')) return true;

  return fromDate.isBefore(toDate);
}

function testLaterThanOccurence35BSpanDate(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);

  const fromValue = formValues[UB04_FIELDS.occurrenceSpan35BFrom];
  if (!fromValue) return true;
  const fromDate = moment(fromValue).startOf('day');

  const toValue = formValues[UB04_FIELDS.occurrenceSpan35BTo];
  if (!fromValue) return true;
  const toDate = moment(toValue).startOf('day');

  if (!toDate.isValid()) return true;
  if (!fromDate.isValid()) return true;
  if (fromDate.isSame(toDate, 'day')) return true;

  return fromDate.isBefore(toDate);
}
function testLaterThanOccurence36ASpanDate(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);

  const fromValue = formValues[UB04_FIELDS.occurrenceSpan36AFrom];
  if (!fromValue) return true;
  const fromDate = moment(fromValue).startOf('day');

  const toValue = formValues[UB04_FIELDS.occurrenceSpan36ATo];
  if (!fromValue) return true;
  const toDate = moment(toValue).startOf('day');

  if (!toDate.isValid()) return true;
  if (!fromDate.isValid()) return true;
  if (fromDate.isSame(toDate, 'day')) return true;

  return fromDate.isBefore(toDate);
}

function testLaterThanOccurence36BSpanDate(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);

  const fromValue = formValues[UB04_FIELDS.occurrenceSpan36BFrom];
  if (!fromValue) return true;
  const fromDate = moment(fromValue).startOf('day');

  const toValue = formValues[UB04_FIELDS.occurrenceSpan36BTo];
  if (!fromValue) return true;
  const toDate = moment(toValue).startOf('day');

  if (!toDate.isValid()) return true;
  if (!fromDate.isValid()) return true;
  if (fromDate.isSame(toDate, 'day')) return true;

  return fromDate.isBefore(toDate);
}

function testPatientAddress(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const patientAddress = formValues[UB04_FIELDS.patientAddress];
  if (!patientAddress) return true;
  return Boolean(value);
}

function testTypeOfBillMUST001(value: ValueType, field: string, context: ContextType) {
  let requiredTobs: string[];
  switch (field) {
    case UB04_FIELDS.admissionDate:
      requiredTobs = ['11', '12', '18', '21', '22', '28', '32', '34', '41', '65', '66', '81', '82', '86'];
      break;
    case UB04_FIELDS.admissionHour:
      requiredTobs = ['11', '18', '28', '41', '65', '66', '86'];
      // requiredTobs = ['11', '12'];
      break;
    case UB04_FIELDS.dischargeHour:
      // requiredTobs = ['111', '114', '181', '184', '281', '284', '411', '414', '651', '654', '661', '664', '861', '864'];
      // requiredTobs = ['11', '12'];
      requiredTobs = ['11', '18', '21', '28', '41', '65', '66', '86', '12', '22', '32', '34', '81', '82'];
      break;
    default:
      requiredTobs = [];
  }
  const formValues = getFormValues(context);
  let typeOfBill = formValues[UB04_FIELDS.typeOfBill];
  if (!typeOfBill) return true;
  if (!value) {
    if (typeOfBill.length === 4) typeOfBill = typeOfBill.slice(1);
    if (requiredTobs.some((_tob) => typeOfBill.startsWith(_tob))) return Boolean(value);
  }
  return true;
}

function testTypeOfBillTOB004(value: ValueType, context: ContextType) {
  const requiredTobs = ['11', '12', '18', '22'];
  const formValues = getFormValues(context);
  let typeOfBill = formValues[UB04_FIELDS.typeOfBill];
  if (!typeOfBill) return true;
  if (typeOfBill.length < 3) return true;
  if (typeOfBill.length === 4) typeOfBill = typeOfBill.slice(1);
  if (requiredTobs.some((_tob) => typeOfBill.startsWith(_tob))) return Boolean(value);
  return true;
}

function testAdmissionDateToBill4Required(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const typeOfBill = formValues[UB04_FIELDS.typeOfBill];
  const shouldRunCheck =
    typeOfBill && typeof typeOfBill === 'string' && (typeOfBill.startsWith('11') || typeOfBill.startsWith('12'));
  if (!shouldRunCheck) return true;
  return Boolean(value);
}

function testAdmissionDateToBill4FromTo(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  // const typeOfBill = formValues[UB04_FIELDS.typeOfBill];
  // const shouldRunCheck =
  //   typeOfBill &&
  //   typeof typeOfBill === 'string' &&
  //   (typeOfBill.startsWith('11') ||
  //     typeOfBill.startsWith('12') ||
  //     typeOfBill.startsWith('011') ||
  //     typeOfBill.startsWith('012'));
  // if (!shouldRunCheck) return true;
  const parsedValue = moment.utc(value);
  if (!parsedValue.isValid()) return true;
  const smtpFrom = formValues[UB04_FIELDS.coversPeriodFrom];
  const smtpTo = formValues[UB04_FIELDS.coversPeriodTo];
  const smtpFromParsed = moment(smtpFrom).startOf('day');
  const smtpToParsed = moment(smtpTo).endOf('day');
  const isAfterFrom = !smtpFrom || !smtpFromParsed.isValid() || parsedValue.isSameOrAfter(smtpFromParsed);
  const isBeforeTo = !smtpTo || !smtpToParsed.isValid() || parsedValue.isSameOrBefore(smtpToParsed);

  return isAfterFrom && isBeforeTo;
}

function testTypeOfBillTOB005(value: ValueType, context: ContextType) {
  const requiredTobs = ['14'];
  const formValues = getFormValues(context);
  let typeOfBill = formValues[UB04_FIELDS.typeOfBill];
  if (!typeOfBill) return true;
  if (typeOfBill.length < 3) return true;
  if (typeOfBill.length === 4) typeOfBill = typeOfBill.slice(1);
  if (requiredTobs.some((_tob) => typeOfBill.startsWith(_tob))) return true;
  return Boolean(value);
}

function testTypeOfBillTOB006(value: ValueType, context: ContextType) {
  const requiredTobs = ['13', '78', '85'];
  const formValues = getFormValues(context);
  let typeOfBill = formValues[UB04_FIELDS.typeOfBill];
  if (!typeOfBill) return true;
  if (typeOfBill.length < 3) return true;
  if (typeOfBill.length === 4) typeOfBill = typeOfBill.slice(1);
  if (requiredTobs.some((_tob) => typeOfBill.startsWith(_tob))) return Boolean(value);
  return true;
}

function testResponsiblePartyAddress(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.responsiblePartyAddress];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testNdcUnit(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor42ServicesAntd(context.options.context?.path)
    : extractIndexFor42ServicesYup(context.path);
  if (index === null || index === undefined) return true;
  const fieldValue = formValues?.[UB04_FIELDS.servicesList]?.[index]?.[UB04_FIELDS.desc_ndc_unit];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testNdcCode(value: ValueType, context: ContextType) {
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor42ServicesAntd(context.options.context?.path)
    : extractIndexFor42ServicesYup(context.path);
  if (index === null || index === undefined) return true;
  const formValues = getFormValues(context);
  const ndcCode = formValues?.[UB04_FIELDS.servicesList]?.[index]?.[UB04_FIELDS.desc_ndc_code];
  if (!ndcCode) return true;
  return Boolean(value);
}

function testOtherProvider1LastName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.otherProvider1LastName];
  if (!fieldValue) return true;
  return Boolean(value);
}
function testOtherProvider1FirstName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.otherProvider1FirstName];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testOtherProvider1Npi(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.otherProvider1Npi];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testOperatingProviderNpiFilled(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.operatingProviderNpi];
  if (fieldValue) return true;
  return !Boolean(value);
}

function testOtherProvider2LastName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.otherProvider2LastName];
  if (!fieldValue) return true;
  return Boolean(value);
}
function testOtherProvider2FirstName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.otherProvider2FirstName];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testOtherProvider2Npi(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.otherProvider2Npi];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testAttendingProviderLastName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.attendingProviderLastName];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testProviderNameNpiAndIdentifier(
  value: ValueType,
  context: ContextType,
  lastId: string,
  firstId: string,
  identifierId: string,
  npiId: string
) {
  const formValues = getFormValues(context);
  const lastName = formValues[lastId];
  const firstName = formValues[firstId];
  const identifier = formValues[identifierId];
  const npi = formValues[npiId];
  if (Boolean(lastName) && Boolean(firstName) && !identifier && !npi) return false;
  return true;
}

function testOperatingProviderNameAndNpi(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const lastName = formValues[UB04_FIELDS.operatingProviderFirst];
  const firstName = formValues[UB04_FIELDS.operatingProviderFirstName];
  const identifier = formValues[UB04_FIELDS.operatingProviderId];
  const npi = formValues[UB04_FIELDS.operatingProviderNpi];
  if (lastName && firstName && npi && !identifier) return true;
  return Boolean(value);
}

function testOperatingProviderNameAndIdentifier(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const lastName = formValues[UB04_FIELDS.operatingProviderFirst];
  const firstName = formValues[UB04_FIELDS.operatingProviderFirstName];
  const identifier = formValues[UB04_FIELDS.operatingProviderId];
  const npi = formValues[UB04_FIELDS.operatingProviderNpi];
  if (lastName && firstName && identifier && !npi) return true;
  return Boolean(value);
}

function testAttendingProviderNpi(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.attendingProviderNpi];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testOperatingProviderLastName(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.operatingProviderLastName];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testOperatingProviderIsEmpty(
  value: ValueType,
  childValues: ValueType[],
  dependantValues: ValueType[]
): boolean {
  let isNotEmpty = true;
  childValues.forEach((childValue, idx) => {
    if (childValue && ValuesToTestEmptyProvider.includes(dependantValues?.[idx]) && !value) isNotEmpty = false;
  });
  return isNotEmpty;
}

function testOperatingProviderNpi(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.operatingProviderNpi];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testBillingNpi(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.billingName];
  if (!fieldValue && !value) return false;
  return Boolean(value);
}

function testICDValueZeroMin(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.codeQualifier];
  if (fieldValue == 0 || !fieldValue) {
    if (value?.length < 3) {
      return false;
    } else {
      return true;
    }
  }
  return true;
}

function testICDValueZeroMax(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.codeQualifier];

  if (fieldValue == 0 || !fieldValue) {
    if (value?.length > 7) {
      return false;
    } else {
      return true;
    }
  }
  return true;
}

function testICDValueNineMin(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.codeQualifier];

  if (!fieldValue) return true;

  if (fieldValue == 9) {
    if (value?.length < 3) {
      return false;
    }
  }
  return true;
}

function testICDValueNineMax(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.codeQualifier];

  if (!fieldValue) return true;

  if (fieldValue == 9) {
    if (value?.length > 5) {
      return false;
    }
  }
  return true;
}

function testValueAmountCode(value: ValueType, context: ContextType) {
  const pairName = context.path.split('_')[0];
  const codeKey = `${pairName}_value_code`;
  const formValues = getFormValues(context);
  const code = formValues[codeKey];
  return Boolean(value) === Boolean(code);
}

function testDocumentControlNumber64aPattern(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const payer50A = formValues[UB04_FIELDS.payer50A];
  const payer50B = formValues[UB04_FIELDS.payer50B];
  const payer50C = formValues[UB04_FIELDS.payer50C];
  const regexPattern = /0[0-9]$/;
  if (payer50A && !payer50B && !payer50C) {
    if (!value) return true;
    return regexPattern.test(value);
  } else {
    return true;
  }
}
function testDocumentControlNumber64bPattern(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const payer50A = formValues[UB04_FIELDS.payer50A];
  const payer50B = formValues[UB04_FIELDS.payer50B];
  const payer50C = formValues[UB04_FIELDS.payer50C];
  const regexPattern = /0[0-9]$/;
  if (payer50A && payer50B && !payer50C) {
    if (!value) return true;
    return regexPattern.test(value);
  } else {
    return true;
  }
}
function testDocumentControlNumber64cPattern(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const payer50A = formValues[UB04_FIELDS.payer50A];
  const payer50B = formValues[UB04_FIELDS.payer50B];
  const payer50C = formValues[UB04_FIELDS.payer50C];
  const regexPattern = /0[0-9]$/;
  if (payer50A && payer50B && payer50C) {
    if (!value) return true;
    return regexPattern.test(value);
  } else {
    return true;
  }
}

function testDocumentControlNumber64aRequired(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const typeOfBill: string = formValues[UB04_FIELDS.typeOfBill];
  if (!typeOfBill) return true;
  if (typeOfBill.endsWith('7') || typeOfBill.endsWith('8')) return Boolean(value);
  return true;
}

function testNonCoveredChargesLowerThanTotal(value: ValueType, context: ContextType) {
  if (!value) return true;
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor42ServicesAntd(context.options.context?.path)
    : extractIndexFor42ServicesYup(context.path);
  if (index === null || index === undefined) return true;
  const formValues = getFormValues(context);
  const total = formValues[UB04_FIELDS.servicesList][index][UB04_FIELDS.total_charges_47];
  if (!total) return true;
  return Number(total) >= Number(value);
}

function testTotalChargesSum(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  let total = 0;
  const length = formValues[UB04_FIELDS.servicesList].length;

  for (let i = 0; i < length; i++) {
    total = formValues[UB04_FIELDS.servicesList][i][UB04_FIELDS.total_charges_47];
  }

  return false;
}

function testOcurrenceSpanCode(value: ValueType, context: ContextType) {
  const ocurrenceSpanKey = context.path.split('_')[0];
  if (!ocurrenceSpanKey) return true;
  const formValues = getFormValues(context);
  const code = formValues[`${ocurrenceSpanKey}_span_code`];
  if (!code && value) return false;
  return true;
}

function testOcurrenceSpanEarlierFrom(value: ValueType, context: ContextType) {
  if (!value) return true;
  const date = moment(value).startOf('day');
  if (!date.isValid()) return true;
  const ocurrenceKey = context.path.split('_')[0];
  if (!ocurrenceKey) return true;
  const formValues = getFormValues(context);
  const ocurrenceFrom = formValues[`${ocurrenceKey}_span_from`];
  if (!ocurrenceFrom) return true;
  const ocurrenceFromDate = moment(ocurrenceFrom).startOf('day');
  if (!ocurrenceFromDate.isValid()) return true;
  return ocurrenceFromDate.isSameOrBefore(date);
}

function testOcurrenceSpanEqualFrom(value: ValueType, context: ContextType, fromDate: ValueType) {
  if (!value) return true;
  const date = moment.utc(value).startOf('day');
  if (!date.isValid()) return true;

  if (fromDate) {
    if (!fromDate.isValid()) return true;
    const ocurrenceFrom = moment(fromDate).startOf('day');

    return !ocurrenceFrom.isSame(date, 'day');
  } else {
    const ocurrenceKey = context.path.split('_')[0];
    if (!ocurrenceKey) return true;

    const formValues = getFormValues(context);
    const ocurrenceFromStr = formValues[`${ocurrenceKey}_span_from`];
    if (!ocurrenceFromStr) return true;

    const ocurrenceFromDate = moment.utc(ocurrenceFromStr).startOf('day');
    if (!ocurrenceFromDate.isValid()) return true;
    return !ocurrenceFromDate.isSame(date, 'day');
  }
}

function testOcurrenceSpanEqualFrom36b(value: ValueType, context: ContextType) {
  if (!value) return true;
  const date = moment(value).startOf('day');
  if (!date.isValid()) return true;
  const formValues = getFormValues(context);
  const occurrenceSpan36BFrom = formValues[UB04_FIELDS.occurrenceSpan36BFrom];
  const occurrenceSpan36BTo = formValues[UB04_FIELDS.occurrenceSpan36BTo];
  if (date) {
    if (!date.isValid()) return true;
    const ocurrenceFrom = moment(occurrenceSpan36BFrom).startOf('day');
    const ocurrenceTo = moment(occurrenceSpan36BTo).startOf('day');
    return !ocurrenceFrom.isSame(ocurrenceTo);
  } else {
    const ocurrenceKey = context.path.split('_')[0];
    if (!ocurrenceKey) return true;
    const formValues = getFormValues(context);
    const ocurrenceFrom = formValues[`${ocurrenceKey}_span_from`];
    if (!ocurrenceFrom) return true;
    const ocurrenceFromDate = moment(ocurrenceFrom).startOf('day');
    if (!ocurrenceFromDate.isValid()) return true;
    return !ocurrenceFromDate.isSame(date);
  }
}

function testProcedureCode(value: ValueType, context: ContextType) {
  const procedureKey = context.path.split('_proc')[0];
  if (!procedureKey) return true;
  const formValues = getFormValues(context);
  const code = formValues[`${procedureKey}_proc_code`];
  if (code) return Boolean(value);
  return true;
}

function testLowerEqual0(value: ValueType, context: ContextType) {
  if (Number.isNaN(value)) return true;
  return Number(value) > 0;
}

function testAttendingProviderId(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.attendingProviderId];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testAttendingProviderQual(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.attendingProviderQual];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testOperatingProviderId(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.operatingProviderId];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testOperatingProviderQual(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.operatingProviderQual];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testOtherProvider1Id(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.otherProvider1Id];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testOtherProvider1Qual(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.otherProvider1Qual];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testOtherProvider2Id(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.otherProvider2Id];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testOtherProvider2Qual(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const fieldValue = formValues[UB04_FIELDS.otherProvider2Qual];
  if (!fieldValue) return true;
  return Boolean(value);
}

function testPayer(value: ValueType, context: ContextType) {
  const payerKey = context.path.split('_')[0].replace('58', '');
  if (!payerKey) return true;
  const formValues = getFormValues(context);
  const payer = formValues[`payer_destination_${payerKey}`];
  if (payer !== 'YES') return true;
  return Boolean(value);
}

function testModifiersToCPT1(value: ValueType, context: ContextType) {
  return testModifiers(value, context, ['modifier_2', 'modifier_3', 'modifier_4']);
}

function testModifiersToCPT2(value: ValueType, context: ContextType) {
  return testModifiers(value, context, ['modifier_1', 'modifier_3', 'modifier_4']);
}

function testModifiersToCPT3(value: ValueType, context: ContextType) {
  return testModifiers(value, context, ['modifier_1', 'modifier_2', 'modifier_4']);
}

function testModifiersToCPT4(value: ValueType, context: ContextType) {
  return testModifiers(value, context, ['modifier_1', 'modifier_2', 'modifier_3']);
}

// function testModifiersToCPT5(value: ValueType, context: ContextType) {
//   return testModifiers(value, context, [
//     'modifier_1',
//     'modifier_2',
//     'modifier_3',
//     'modifier_4',
//     'modifier_6',
//     'modifier_7',
//     'modifier_8',
//     'modifier_9',
//     'modifier_10'
//   ]);
// }

// function testModifiersToCPT6(value: ValueType, context: ContextType) {
//   return testModifiers(value, context, [
//     'modifier_1',
//     'modifier_2',
//     'modifier_3',
//     'modifier_4',
//     'modifier_5',
//     'modifier_7',
//     'modifier_8',
//     'modifier_9',
//     'modifier_10'
//   ]);
// }

// function testModifiersToCPT7(value: ValueType, context: ContextType) {
//   return testModifiers(value, context, [
//     'modifier_1',
//     'modifier_2',
//     'modifier_3',
//     'modifier_4',
//     'modifier_5',
//     'modifier_6',
//     'modifier_8',
//     'modifier_9',
//     'modifier_10'
//   ]);
// }

// function testModifiersToCPT8(value: ValueType, context: ContextType) {
//   return testModifiers(value, context, [
//     'modifier_1',
//     'modifier_2',
//     'modifier_3',
//     'modifier_4',
//     'modifier_5',
//     'modifier_6',
//     'modifier_7',
//     'modifier_9',
//     'modifier_10'
//   ]);
// }

// function testModifiersToCPT9(value: ValueType, context: ContextType) {
//   return testModifiers(value, context, [
//     'modifier_1',
//     'modifier_2',
//     'modifier_3',
//     'modifier_4',
//     'modifier_5',
//     'modifier_6',
//     'modifier_7',
//     'modifier_8',
//     'modifier_10'
//   ]);
// }

// function testModifiersToCPT10(value: ValueType, context: ContextType) {
//   return testModifiers(value, context, [
//     'modifier_1',
//     'modifier_2',
//     'modifier_3',
//     'modifier_4',
//     'modifier_5',
//     'modifier_6',
//     'modifier_7',
//     'modifier_8',
//     'modifier_9'
//   ]);
// }

function testIfLargerThanTotalChargesA(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const priorPayments54A = formValues[UB04_FIELDS.priorPayments54A];
  const totalChargesSum: string = formValues[UB04_FIELDS.ub_total_charges_sum];
  const priorPayments54AValue = priorPayments54A ? priorPayments54A : 0;
  const valueValue = value ? value : 0;
  const payerOneSum = Number(priorPayments54AValue) + Number(valueValue);
  const isLargerThanTotalCharges = payerOneSum > Number(totalChargesSum);
  if (isLargerThanTotalCharges) return false;
  return true;
}

function testIfLargerThanTotalChargesB(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const priorPayments54B = formValues[UB04_FIELDS.priorPayments54B];
  const totalChargesSum: string = formValues[UB04_FIELDS.ub_total_charges_sum];
  const priorPayments54BValue = priorPayments54B ? priorPayments54B : 0;
  const valueValue = value ? value : 0;
  const payerOneSum = Number(priorPayments54BValue) + Number(valueValue);
  const isLargerThanTotalCharges = payerOneSum > Number(totalChargesSum);
  if (isLargerThanTotalCharges) return false;
  return true;
}

function testIfLargerThanTotalChargesC(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const priorPayments54C = formValues[UB04_FIELDS.priorPayments54C];
  const totalChargesSum: string = formValues[UB04_FIELDS.ub_total_charges_sum];
  const priorPayments54CValue = priorPayments54C ? priorPayments54C : 0;
  const valueValue = value ? value : 0;
  const payerOneSum = Number(priorPayments54CValue) + Number(valueValue);
  const isLargerThanTotalCharges = payerOneSum > Number(totalChargesSum);
  if (isLargerThanTotalCharges) return false;
  return true;
}

function testIfLargerThanTotalCharges54A(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const estimatedAmount55A = formValues[UB04_FIELDS.estimatedAmount55A];
  const totalChargesSum: string = formValues[UB04_FIELDS.ub_total_charges_sum];
  const estimatedAmount55AValue = estimatedAmount55A ? estimatedAmount55A : 0;
  const valueValue = value ? value : 0;
  const payerOneSum = Number(estimatedAmount55AValue) + Number(valueValue);
  const isLargerThanTotalCharges = payerOneSum > Number(totalChargesSum);
  if (isLargerThanTotalCharges) return false;
  return true;
}

function testIfLargerThanTotalCharges54B(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const estimatedAmount55B = formValues[UB04_FIELDS.estimatedAmount55B];
  const totalChargesSum: string = formValues[UB04_FIELDS.ub_total_charges_sum];
  const estimatedAmount55BValue = estimatedAmount55B ? estimatedAmount55B : 0;
  const valueValue = value ? value : 0;
  const payerOneSum = Number(estimatedAmount55BValue) + Number(valueValue);
  const isLargerThanTotalCharges = payerOneSum > Number(totalChargesSum);
  if (isLargerThanTotalCharges) return false;
  return true;
}

function testIfLargerThanTotalCharges54C(value: ValueType, context: ContextType) {
  const formValues = getFormValues(context);
  const priorPayments54C = formValues[UB04_FIELDS.priorPayments54C];
  const totalChargesSum: string = formValues[UB04_FIELDS.ub_total_charges_sum];
  const priorPayments54CValue = priorPayments54C ? priorPayments54C : 0;
  const valueValue = value ? value : 0;
  const payerOneSum = Number(priorPayments54CValue) + Number(valueValue);
  const isLargerThanTotalCharges = payerOneSum > Number(totalChargesSum);
  if (isLargerThanTotalCharges) return false;
  return true;
}

// testModifiersToCPT2,
// testModifiersToCPT3,
// testModifiersToCPT4,
// testModifiersToCPT5,
// testModifiersToCPT6,
// testModifiersToCPT7,
// testModifiersToCPT8,
// testModifiersToCPT9,
// testModifiersToCPT10,

function extractIndexFor24TableAntd(modelString: string): number | null {
  const regex = /^42Services\.(\d+)\..+$/;
  const match = modelString.match(regex);

  if (match && match[1]) {
    const index = parseInt(match[1], 10);
    return isNaN(index) ? null : index;
  }

  return null;
}

function extractIndexFor24TableYup(inputString: string): number | null {
  const regexPattern = /42Services\[(\d+)\]\.\w+/;

  const match = inputString.match(regexPattern);

  if (match && match[1]) {
    return parseInt(match[1], 10);
  } else {
    return null;
  }
}

function testModifiers(value: ValueType, context: ContextType, toTest: [string, string, string]) {
  const formValues = getFormValues(context);
  if (!value) return true;
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor24TableAntd(context.options.context?.path)
    : extractIndexFor24TableYup(context.path);
  if (index === null) return true;
  const tableValues = formValues['42Services'];
  if (!tableValues) return true;
  const row = tableValues[index];
  if (!row) return true;
  const hasDuplicate = toTest.some((path) => row[path] && row[path] == value);
  return !hasDuplicate;
}

async function revenueCodeLookup(value: ValueType) {
  if (!value) return true;
  if (value.length < 2 || value.length > 5) return true;
  const token = getTokenFromSessionStorage();
  try {
    const res = await revCodeLookup(value, token);
    return res.isValid;
  } catch (e) {
    return false;
  }
}

async function occuranceCodeLookup(value: ValueType) {
  if (!value) return true;
  if (value.length < 1 || value.length > 2) return true;
  const token = getTokenFromSessionStorage();
  try {
    const res = await occurCodeLookup(value, token);
    return res.isValid;
  } catch (e) {
    return false;
  }
}

async function testValueCodeLookup(value: ValueType) {
  if (!value) return true;
  if (value.length !== 2) return true;
  const token = getTokenFromSessionStorage();
  try {
    const res = await valueCodeLookup(value, token);
    return res.isValid;
  } catch (e) {
    return false;
  }
}

async function testConditionCodeLookup(value: ValueType) {
  if (!value) return true;
  if (value.length !== 2) return true;
  const token = getTokenFromSessionStorage();
  try {
    const res = await condCodeLookup(value, token);
    return res.isValid;
  } catch (e) {
    return false;
  }
}

async function testProcedureCodeLookup(value: ValueType) {
  if (!value) return true;
  if (value.length < 1 || value.length > 6) return true;
  const token = getTokenFromSessionStorage();
  try {
    const res = await procedureLookup(value, token);
    return res.isValid;
  } catch (e) {
    return false;
  }
}

function testRequiredOccurrenceDate(value: ValueType, context: ContextType) {
  const _occurrenceKey = context.path.split('_')[0];
  if (!_occurrenceKey) return true;
  const formValues = getFormValues(context);
  const _occurrenceCode = formValues[`${_occurrenceKey}_span_code`];
  if (!_occurrenceCode) return true;
  if (!value) return false;
  const date = moment(value).startOf('day');
  if (!date.isValid()) return false;
  return true;
}

function testRequiredPayerPopulated(value: ValueType, context: ContextType) {
  const _key = context.path.substring(2, 3);
  if (!_key) return true;
  const _payerKey = `50${_key}_payer`;
  const formValues = getFormValues(context);
  const _payer = formValues[_payerKey];
  if (!_payer) return true;
  return Boolean(value);
}
function testRequiredLenghByLob(value: ValueType, context: ContextType) {
  // const lob = sessionStorage.getItem('lob');
  const formValues = getFormValues(context);
  const patientState = formValues[UB04_FIELDS.patientState];

  if (patientState === 'MI' && value) {
    if (/[a-zA-Z]/.test(value)) {
      if (/^[0-9]/.test(value) && /[0-9]$/.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  return true;
}
function testRequiredRegexNumericByLob(value: ValueType, context: ContextType) {
  const numericRegex = /^[0-9]*$/;
  // const lob = sessionStorage.getItem('lob');
  if (!value) return true;
  const lob = sessionStorage.getItem('lob');
  if (lob && lob.toLowerCase() === 'wi') return true;
  const formValues = getFormValues(context);
  const patientState = formValues[UB04_FIELDS.patientState];
  if (patientState !== 'MI' && !numericRegex.test(value)) return false;
  return true;
}

function testRequiredRegexAlphanumByLob(value: ValueType, context: ContextType) {
  // const alphaNumStartsEndsWithNumber = /^\d.*\d$/;
  const alphaNumStartsEndsWithNumber = /^[0-9]+[0-9a-zA-Z]*[0-9]+$/;
  if (!value) return true;

  const lob = sessionStorage.getItem('lob');
  if (lob && lob.toLowerCase() === 'wi') return true;
  const formValues = getFormValues(context);
  const patientState = formValues[UB04_FIELDS.patientState];
  if (patientState !== 'MI') return true;
  if (patientState === 'MI' && !alphaNumStartsEndsWithNumber.test(value)) {
    return false;
  }
  return true;
}

function testUniqueIdIfLobIw(value: ValueType, context: ContextType) {
  if (!value) return true;
  const lob = sessionStorage.getItem('lob');

  if (!lob) return true;
  if (lob && lob.toLowerCase() !== 'wi') return true;
  // lob is IW
  const isAlphaNumWithHyphens = aphaNumWithHyphensRegex.test(value);
  if (!isAlphaNumWithHyphens) return false;
  return value.length >= 9 && value.length <= 15;
}

function testOccurrenceSpanCode(value: ValueType, context: ContextType, yup: any) {
  if (!value) return true;

  const containsAlpha = /[a-zA-Z]/.test(value);
  const containsNumber = /\d/.test(value);
  const isAlphaNum = containsAlpha || containsNumber;

  if (!isAlphaNum) {
    throw new yup.ValidationError(
      'Value entered is not valid, please re-enter correct value [A-Z and 0-9]',
      value,
      context.path
    );
  }

  if (isAlphaNum && value.length > 2) {
    throw new yup.ValidationError(
      'Field does not meet length requirement, please re-enter correct value (1-2)',
      value,
      context.path
    );
  }

  return true;
}

function testOtherTypeValue(value: ValueType, context: ContextType) {
  if (!value) return true;
  const formValues = getFormValues(context);
  const otherType78 = formValues[UB04_FIELDS.otherType78];
  const otherType79 = formValues[UB04_FIELDS.otherType79];

  const otherProvider1FirstName = formValues[UB04_FIELDS.otherProvider1FirstName] ?? '';
  const otherProvider1LastName = formValues[UB04_FIELDS.otherProvider1LastName] ?? '';
  const field78FullName = otherProvider1FirstName + otherProvider1LastName;

  const otherProvider2FirstName = formValues[UB04_FIELDS.otherProvider2FirstName] ?? '';
  const otherProvider2LastName = formValues[UB04_FIELDS.otherProvider2LastName] ?? '';
  const field79FullName = otherProvider2FirstName + otherProvider2LastName;

  const shouldReturnFalse = !otherType78 && !otherType79 && field78FullName !== field79FullName;

  if (shouldReturnFalse) {
    return false;
  }

  return true;
}

function testOtherTypeValueTest(value: ValueType, context: ContextType) {
  return false;
}

function testIsOccurenceSpanEmpty(value: ValueType, context: ContextType, fromDate: any, toDate: any) {
  const formValues = getFormValues(context);
  const fromDateValue = formValues[fromDate];
  const toDateValue = formValues[toDate];

  const isAtLeastOneDateFilled = fromDateValue || toDateValue;

  if (!value && isAtLeastOneDateFilled) {
    return false;
  }

  return true;
}

function testIsOccurenceCodeEmpty(value: ValueType, context: ContextType, occurrenceCode: any) {
  const formValues = getFormValues(context);
  const occurrenceCodeValue = formValues[occurrenceCode];

  if (!value && occurrenceCodeValue) {
    return false;
  }

  return true;
}

function testIsNumberIfAlphaNum(value: ValueType, context: ContextType) {
  if (!value) return true;
  const formValues = getFormValues(context);
  const patientState = formValues[UB04_FIELDS.patientState];
  const isAlphaNum = /^[a-zA-Z0-9]+$/.test(value);

  if (patientState === 'MI') {
    if (isAlphaNum) {
      return true;
    } else {
      return false;
    }
  }
  return true;
}

function testIsNumberIfNum(value: ValueType, context: ContextType) {
  if (!value) return true;
  const formValues = getFormValues(context);
  const patientState = formValues[UB04_FIELDS.patientState];
  const isNum = /^[0-9]+$/.test(value);

  if (patientState !== 'MI') {
    if (isNum) {
      return true;
    } else {
      return false;
    }
  }

  return true;
}

function testIsBeforeFromServiceDate(value: ValueType, context: ContextType) {
  if (!value) return true;
  const formValues = getFormValues(context);
  const fromValue = formValues[UB04_FIELDS.coversPeriodFrom];

  if (!fromValue) return true;
  const fromDate = moment(fromValue).startOf('day');
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor42ServicesAntd(context.options.context?.path)
    : extractIndexFor42ServicesYup(context.path);
  if (index === null || index === undefined) return true;
  const toValue = formValues?.[UB04_FIELDS.servicesList]?.[index]?.[UB04_FIELDS.dos_45];

  if (!toValue) return true;
  const toDate = moment(toValue).startOf('day');

  if (!toDate.isValid()) return true;
  if (!fromDate.isValid()) return true;
  if (fromDate.isSame(toDate, 'day')) return true;

  return fromDate.isBefore(toDate);
}

function testIsAfterToServiceDate(value: ValueType, context: ContextType) {
  if (!value) return true;
  const formValues = getFormValues(context);
  const fromValue = formValues[UB04_FIELDS.coversPeriodTo];

  if (!fromValue) return true;
  const fromDate = moment(fromValue).startOf('day');
  const isAntd = Boolean(context.options.context?.path);
  const index = isAntd
    ? extractIndexFor42ServicesAntd(context.options.context?.path)
    : extractIndexFor42ServicesYup(context.path);
  if (index === null || index === undefined) return true;
  const toValue = formValues?.[UB04_FIELDS.servicesList]?.[index]?.[UB04_FIELDS.dos_45];

  if (!toValue) return true;
  const toDate = moment(toValue).startOf('day');

  if (!toDate.isValid()) return true;
  if (!fromDate.isValid()) return true;
  if (fromDate.isSame(toDate, 'day')) return true;

  return fromDate.isAfter(toDate);
}

function testPhysicalAddress(value: ValueType, context: ContextType) {
  if (!value || typeof value !== 'string') return true;
  // const lob = sessionStorage.getItem('lob');
  const statesExcluded = ['in', 'ky', 'ga', 'wv'];
  const formValues = getFormValues(context);
  const patientstate = formValues[UB04_FIELDS.patientState];
  // const state = formValues[UB04_FIELDS.insuredState];
  // console.log('test ', patientstate, state);
  if (
    // (lob && !states.includes(lob.toLowerCase())) ||
    patientstate &&
    !statesExcluded.includes(patientstate.toLowerCase())
    // (state && !statesExcluded.includes(state.toLowerCase()))
  ) {
    const isPOBox = checkIsPostBox(value);
    const formValues = getFormValues(context);
    const addressTwo = formValues[UB04_FIELDS.billing_addr2];
    if (isPOBox && !addressTwo) return false;
  }
  return true;
}

export {
  ValidationsDependenciesMap,
  // testTableUnits,
  testInsured58bLastName,
  testInsured58bFirstName,
  testInsured58cLastName,
  testInsured58cFirstName,
  testProviderNameToSupplier,
  testProviderNameToPhysician,
  testCodeQualifier,
  testTomorrowsDate,
  testTotalChargeToTableFields,
  testBillingAddress,
  testPayToAddress,
  testTypeOfBill3Char,
  testTypeOfBill4Char,
  testFutureDate,
  testOcurrenceSpanEqualFrom,
  testOcurrenceSpanEqualFrom36b,
  testEarlierCoversPeriodFrom,
  testEarlierPatientDOB,
  testEarlierPatientDOBById,
  testMoreThan1Year,
  testLaterThanOccurence35SpanDate,
  testLaterThanOccurence35BSpanDate,
  testLaterThanOccurence36ASpanDate,
  testLaterThanOccurence36BSpanDate,
  testLaterThanServiceDate,
  fromDataConditionedByBox50Fields,
  // testPayerRegexCheck,
  testPatientAddress,
  testTypeOfBillTOB004,
  testTypeOfBillTOB005,
  testTypeOfBillTOB006,
  testResponsiblePartyAddress,
  testNdcUnit,
  testNdcCode,
  testOtherProvider1LastName,
  testOtherProvider1Npi,
  testOperatingProviderNpiFilled,
  testOtherProvider2LastName,
  testOtherProvider2Npi,
  testAttendingProviderLastName,
  testUniqueIdIfLobIw,
  // testAttendingProviderNameAndNpi,
  // testAttendingProviderNameAndIdentifier,
  testProviderNameNpiAndIdentifier,
  testOperatingProviderNameAndNpi,
  testOperatingProviderNameAndIdentifier,
  testAttendingProviderNpi,
  testOperatingProviderLastName,
  testOperatingProviderNpi,
  testICDValueZeroMin,
  testICDValueZeroMax,
  testICDValueNineMin,
  testICDValueNineMax,
  testValueAmountCode,
  testDocumentControlNumber64aPattern,
  testDocumentControlNumber64bPattern,
  testDocumentControlNumber64cPattern,
  testDocumentControlNumber64aRequired,
  testNonCoveredChargesLowerThanTotal,
  testTotalChargesSum,
  testOcurrenceSpanCode,
  testOcurrenceSpanEarlierFrom,
  testProcedureCode,
  testLowerEqual0,
  testAttendingProviderId,
  testAttendingProviderQual,
  testOperatingProviderId,
  testOperatingProviderQual,
  testOtherProvider1Id,
  testOtherProvider1Qual,
  testOtherProvider2Id,
  testOtherProvider2Qual,
  testPayer,
  testBillingNpi,
  testModifiersToCPT1,
  testModifiersToCPT2,
  testModifiersToCPT3,
  testModifiersToCPT4,
  testIfLargerThanTotalCharges54A,
  testIfLargerThanTotalCharges54B,
  testIfLargerThanTotalCharges54C,
  testIfLargerThanTotalChargesA,
  testIfLargerThanTotalChargesB,
  testIfLargerThanTotalChargesC,
  testOperatingProviderIsEmpty,
  ValidationTableDependenciesMap,
  revenueCodeLookup,
  occurCodeLookup,
  occuranceCodeLookup,
  testValueCodeLookup,
  testConditionCodeLookup,
  testProcedureCodeLookup,
  testRequiredOccurrenceDate,
  testRequiredPayerPopulated,
  testOccurrenceSpanCode,
  testOtherTypeValue,
  testOtherPayer,
  testTypeOfBillMUST001,
  testIsOccurenceCodeEmpty,
  testRequiredLenghByLob,
  testRequiredRegexAlphanumByLob,
  testIsOccurenceSpanEmpty,
  testRequiredRegexNumericByLob,
  testOtherTypeValueTest,
  testIsNumberIfAlphaNum,
  testIsNumberIfNum,
  testIsBeforeFromServiceDate,
  testIsAfterToServiceDate,
  testOtherProvider1FirstName,
  testOtherProvider2FirstName,
  getDestinationPayer,
  testAfterThanServiceDate,
  testAdmissionDateToBill4Required,
  testAdmissionDateToBill4FromTo,
  testPhysicalAddress
};
