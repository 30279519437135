import { Button, Form, Input, InputRef, Popconfirm } from 'antd';
import React, { useRef, useState } from 'react';
import { HCFA_FIELDS } from '../../../utils/hcfa/fields';
import { getYupValidatorServiceTable } from '../../../utils/hcfa/validations';
import { toPositiveNumber } from '../../../helpers/toPositiveNumber';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';

interface RenderProviderProps {
  field: any;
}

const RenderProvider: React.FC<RenderProviderProps> = ({ field }) => {
  const [confirmOpened, setConformOpened] = useState(false);
  const confirmed = useRef(false);
  const inputRef = useRef<InputRef>(null);
  const { t } = useTranslation();

  const onProviderChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const {
      target: { value }
    } = e;
    if (value.length === 10 && !confirmed.current) {
      setConformOpened(true);
    }
  };

  const onConfirm = () => {
    inputRef.current?.focus();
    setConformOpened(false);
    // confirmed.current = true;
  };

  const onNpiChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.value.length > 0) {
      setConformOpened(false);
    }
  };

  return (
    <>
      <Form.Item
        name={[field.name, HCFA_FIELDS.renderId24]}
        style={{ marginBottom: '5px' }}
        rules={[getYupValidatorServiceTable(t, HCFA_FIELDS.renderId24)]}
      >
        <Input
          placeholder="PROVIDER ID"
          style={{ width: '110px' }}
          onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
          onChange={onProviderChange}
        />
      </Form.Item>
      <div className="RenderProvider__popconfirm-wrapper">
        {confirmOpened && (
          <div className="RenderProvider__popconfirm">
            <div style={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
              <ExclamationCircleOutlined style={{ color: '#ff4d4f' }} />
              &nbsp;
              <span>Please fill in NPI</span>
            </div>
            <Button type="primary" size="small" onClick={onConfirm}>
              OK
            </Button>
          </div>
        )}
        <Form.Item
          name={[field.name, '24j_render_npi']}
          className="mb-0"
          rules={[
            getYupValidatorServiceTable(t, HCFA_FIELDS.renderNPI24)
            //getYupWarningServiceTable(t, HCFA_FIELDS.renderNPI24)
          ]}
        >
          <Input
            ref={inputRef}
            className="HCFA_Npi-field"
            style={{ width: '110px' }}
            onInput={toPositiveNumber}
            onChange={onNpiChange}
            placeholder="NPI"
          />
        </Form.Item>
      </div>
    </>
  );
};

export default RenderProvider;
